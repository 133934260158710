import './style.scss'
import React from 'react'
import { Box, Typography } from '@mui/material'

export const ProjectBodyLayout = ({ title, sub, children }) => {
  return (
    <Box className="project-layout-container">
      <Typography className="project-layout-title">{title}</Typography>

      {Boolean(sub) && (
        <Typography className="project-layout-sub">{sub}</Typography>
      )}

      <Box className="project-layout-container">{children}</Box>
    </Box>
  )
}
