import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import reducer from '../redux'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'user',
    'localization',
    'organization',
    'fieldTransactions',
    'appState',
  ],
}

const middleware = [thunk]
const enhancers = []

if (process.env.NODE_ENV === 'development') {
  const { __REDUX_DEVTOOLS_EXTENSION__ } = window
  if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(
      __REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 500 })
    )
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)
const persistedReducer = persistReducer(persistConfig, reducer)
export const store = createStore(persistedReducer, composedEnhancers)
export const persistor = persistStore(store)
