import { SET_USER_INIT_DATA } from '../user'
import { size } from 'lodash'
export const SET_CURRENT_ORGANIZATION = 'organization/SET_CURRENT_ORGANIZATION'
export const SET_AREA_UNITS = 'organization/SET_AREA_UNITS'

const initialState = {
  myOrganizations: [],
  currentOrganization: false,
  total_records: 0, // TODO: remove this; just take the size of myOrganizations
  area_units: 64, // 64 is acres (default), 100 is hectacres
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INIT_DATA: // comes from user reducer
      return {
        ...state,
        loading: false,
        myOrganizations: action.payload.organizations,
        total_records: size(action.payload.organizations),
        currentOrganization:
          state.currentOrganization === false
            ? size(action.payload.organizations) > 0 &&
              action.payload.organizations[0]
            : state.currentOrganization,
        error: false,
      }
    case SET_CURRENT_ORGANIZATION:
      return {
        ...state,
        currentOrganization: action.payload,
      }
    case SET_AREA_UNITS:
      return {
        ...state,
        area_units: action.area_units,
      }
    default:
      return state
  }
}

export default reducer
