import { SET_APP_ATTR, SET_CONTAINER_WIDTH } from '.'

export const setSelectedItem = ({ selectedItem }) => ({
  type: SET_APP_ATTR,
  selectedItem,
})

export const setContainerWidth = (width) => ({
  type: SET_CONTAINER_WIDTH,
  width,
})
