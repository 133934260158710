import {
  PROJECT_MASKS_RESULT,
  PROJECT_PUSH_NEW_MASK,
  PROJECT_RESULT,
  PROJECT_SET_BOUNDARY,
  PROJECT_SET_MASK_BOUNDARY,
  PROJECT_SET_NEW_FIELDS,
  PROJECT_SET_NEW_MASK_SHAPE,
  RESET_PROJECT_FORM,
  RESULTS,
  SET_PROJECT_ATTR,
  SET_PROJECT_FORM_ATTR,
} from '.'
import { APIS } from '../../services'

export const fetchProjects = async ({ organization_id, requestId }) => {
  let params = {
    organization_id,
  }

  const { result = [] } = await APIS.getProjects(params)

  return {
    type: RESULTS,
    projectList: result,
    requestId,
  }
}

export const fetchProject = async ({
  organization_id,
  project_id,
  singleRequestId,
}) => {
  let params = {
    organization_id,
  }

  const { result = {} } = await APIS.getProjectData(project_id, params)
  return {
    type: PROJECT_RESULT,
    selectedProject: result,
    singleRequestId,
  }
}

export const fetchMasks = async ({
  organization_id,
  ag_field_uuid,
  masksRequestId,
}) => {
  let params = {
    organization_id,
  }

  const { result = {} } = await APIS.getFieldMasks(ag_field_uuid, params)

  const masks = result?.field?.exclusion_layers || []

  return {
    type: PROJECT_MASKS_RESULT,
    masks,
    masksRequestId,
  }
}

export const resetFetchingMasks = ({ masksRequestId }) => ({
  type: PROJECT_MASKS_RESULT,
  masks: [],
  masksRequestId,
})

export const startFetchingProjects = ({ requestId }) => ({
  type: SET_PROJECT_ATTR,
  inFlight: true,
  requestId,
})

export const startFetchingSingleProject = ({ singleRequestId }) => ({
  type: SET_PROJECT_ATTR,
  inFlight: true,
  singleRequestId,
  fieldSelectedId: null,
  maskSelectedId: null,
  masksRequestId: null,
  masks: [],
})

export const startFetchingMasks = ({ masksRequestId }) => ({
  type: SET_PROJECT_ATTR,
  inFlight: true,
  masksRequestId,
})

export const setSelectedFieldId = (fieldSelectedId) => ({
  type: SET_PROJECT_ATTR,
  fieldSelectedId,
})

export const setSelectedMaskId = (maskSelectedId) => ({
  type: SET_PROJECT_ATTR,
  maskSelectedId,
})

export const setNewFieldBoundary = (geometry) => ({
  type: PROJECT_SET_BOUNDARY,
  geometry,
})

export const setNewMaskBoundary = (geometry) => ({
  type: PROJECT_SET_MASK_BOUNDARY,
  geometry,
})

export const pushNewMask = (mask) => ({
  type: PROJECT_PUSH_NEW_MASK,
  mask,
})

export const setNewMaskShape = (newMaskShape = {}) => ({
  type: PROJECT_SET_NEW_MASK_SHAPE,
  newMaskShape,
})

export const setNewFields = (fields = []) => ({
  type: PROJECT_SET_NEW_FIELDS,
  fields,
})

export const setFormAttr = (values) => ({
  type: SET_PROJECT_FORM_ATTR,
  ...values,
})

export const resetForm = () => ({
  type: RESET_PROJECT_FORM,
})
