import React, { Fragment } from 'react'
import { Box, Button } from '@mui/material'

import { useProjectFormUtilities } from '../../../hooks/useProjectUtilities'
import { ProjectBodyLayout } from '../Layout'
import LandingImage from '../../../assets/img/landing_image.png'

const Landing = () => {
  return (
    <ProjectBodyLayout title="Perennial workspaces enable you to manage and execute your Natural Capital Projects in one place.">
      <Box className="project-modal-step-landing">
        <img
          src={LandingImage}
          alt="landing-image"
          className="project-modal-image"
          style={{ width: '100%' }}
        />
      </Box>
    </ProjectBodyLayout>
  )
}

const Footer = () => {
  const { increaseStep } = useProjectFormUtilities()

  return (
    <Fragment>
      <Button className="project-modal-step-button" onClick={increaseStep}>
        Get Started
      </Button>
    </Fragment>
  )
}

Landing.Footer = Footer

export default Landing
