import './style.scss'
import React, { useEffect } from 'react'
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useSelector } from 'react-redux'

import theme from '../../assets/scss/variables.scss'
import useAmplitudeTracking from '../../hooks/useAmplitudeTracking'
import { TRACKED_ELEMENT, AMPLITUDE_EVENT, PROJECTS } from '../../constants'
import { SimpleStepper } from '../SimpleStepper'
import Landing from './Steps/Landing'
import { projectFormStep as projectFormStep_ } from '../../redux/projects/selectors'
import { useProjectFormUtilities } from '../../hooks/useProjectUtilities'
import Types from './Steps/Types'
import Configurations from './Steps/Configurations'
import Demo from './Steps/Demo'

const lookup = [Types, Configurations, Demo]

const ProjectModal = ({ visible, setVisible }) => {
  const { trackEvent } = useAmplitudeTracking()
  const projectFormStep = useSelector(projectFormStep_)
  const { resetForm } = useProjectFormUtilities()

  const Step = lookup[projectFormStep] || Landing

  useEffect(() => {
    trackEvent(AMPLITUDE_EVENT.COMPONENT_SHOWN, {
      type: TRACKED_ELEMENT.MODAL,
      name: 'new_project_modal',
    })
  }, [])

  const handleOnClose = () => {
    setVisible(false)
    resetForm()
  }

  return (
    <Modal open={visible} onClose={handleOnClose}>
      <Box className="project-modal-container">
        <Grid container className="project-modal-grid-parent-container">
          <Grid
            item
            xs={3}
            sx={{ background: theme.mainBlackColor, height: '100%' }}
          >
            <Grid container className="project-modal-left-content">
              <Grid item xs={12} sx={{ padding: '10px' }}>
                <Typography className="project-modal-title-label">
                  Create a new Workspace
                </Typography>
              </Grid>

              <Grid item xs={12} className="project-modal-stepper-container">
                <SimpleStepper
                  steps={PROJECTS.FORM.STEPS}
                  activeStep={projectFormStep}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={9} sx={{ height: '100%' }}>
            <Box className="project-modal-right-content">
              <IconButton
                size="small"
                className="project-close-button"
                onClick={handleOnClose}
              >
                <Close fontSize="inherit" />
              </IconButton>

              <Box className="project-modal-content">
                <Step />
              </Box>

              <Divider />

              <Box className="project-modal-footer-container">
                <Step.Footer onExit={handleOnClose} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default ProjectModal
