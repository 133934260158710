import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled as MuiStyled } from '@mui/material/styles'

export const CustomEmptyContainer = MuiStyled(Box)(
  ({ theme, containerHeight }) => ({
    height: containerHeight,
    padding: '0 30px',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.palette.dark.main,
    fontFamily: 'Degular-Regular, Tahoma, sans-serif',
    '& .title': {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '32px',
    },
    '& .description': {
      fontSize: 14,
      fontWeight: 400,
      textAlign: 'center',
    },
  })
)

/**
 * Renders an empty state component with a title, description, and optional image.
 * @param {string} props.title - The title of the empty state.
 * @param {string} props.description - The description of the empty state.
 * @param {string} [props.containerHeight='100%'] - The height of the container.
 * @param {React.Component} props.Image - The image component to render.
 * @param {string} props.height - The height of the image.
 * @param {Array} props.data - The data to check if the empty state should be shown.
 * @param {React.ReactNode} props.children - The children to render if data is not empty.
 * @param {boolean} props.loading - A flag indicating if the component is in a loading state.
 * @param {boolean} props.forceToShow - A flag indicating if the empty state should be shown regardless of data.
 * @returns {React.ReactNode} The rendered empty state component.
 * If used as the EmptyState of a DataGrid component, the height of this container must be the same height as the table.
 */

const EmptyState = ({
  title,
  description,
  containerHeight = '100%',
  Image,
  imageHeight = '60px',
  data,
  children,
  loading,
  forceToShow,
}) => {
  if (data?.length || loading || forceToShow) return <>{children}</>
  return (
    <CustomEmptyContainer containerHeight={containerHeight}>
      <Box sx={{ height: imageHeight }}>
        <Image style={{ height: '100%' }} />
      </Box>
      <Typography className="title">{title}</Typography>
      <Typography className="description">{description}</Typography>
    </CustomEmptyContainer>
  )
}

export default EmptyState
