import { SET_USER_INIT_DATA, SET_IDENTITY } from './index'
import { RESET_ALL } from '../index.js'
import { APIS } from '../../services'
import { Auth } from 'aws-amplify'
import { notify } from '../../utils/notification'
import * as Sentry from '@sentry/react'

function isUserASampler(organizations) {
  for (const organization of organizations) {
    for (const role of organization.roles) {
      if (role.name == 'Sampler') {
        return true
      }
    }
  }
  return false
}

export const fetchCurrentUser = (history) => (dispatch) => {
  return APIS.initUser().then((res) => {
    const { user, organizations } = res.result
    Sentry.setUser({ email: user.primary_email })
    if (organizations.length === 0) {
      history.push('/no_organizations')
    }
    if (isUserASampler(organizations)) {
      history.push('/samplers')
    }
    dispatch({
      type: SET_USER_INIT_DATA,
      payload: { user, organizations },
    })
  })
}

export const completeLogin = (identity) => (dispatch) => {
  dispatch({
    type: SET_IDENTITY,
    identity,
  })
}

export const logout = (history, errorMessage) => async (dispatch) => {
  localStorage.clear()
  dispatch({ type: RESET_ALL })
  Sentry.setUser(null)
  await Auth.signOut()
  history.push('/')
  if (errorMessage) {
    notify(errorMessage, 'error')
  }
}
