/**
 * Updates the value of items in the filter model.
 * This function ensures that if the user uses the same filter item for one
 * filter and then for another, the value will be reset and we will not assign
 * incorrect values in the process.
 * @param {Array} items - The array of items to update.
 * @param {Object} filterModel - The filter model containing the items.
 * @return {Array} The updated array of items.
 */

const updateFilterModelItemsValue = (items, filterModel) => {
  if (!filterModel || !filterModel.items) {
    return items
  }

  return items.map((newItem) => {
    const existingItem = filterModel.items.find(
      (item) => item.id === newItem.id
    )
    const shouldClearValue = existingItem
      ? existingItem.field !== newItem.field &&
        existingItem.value === newItem.value
      : !newItem.value

    return shouldClearValue ? { ...newItem, value: '' } : newItem
  })
}
export default updateFilterModelItemsValue
