/**
 * A custom error class for MissingFieldFormDataError.
 * Used when an ag field's form data is empty or undefined.
 *
 * @param {string} message - The error message.
 */
class MissingFieldFormDataError extends Error {
  constructor(message) {
    super(message)
    this.name = 'MissingFieldFormDataError'
  }
}

export default MissingFieldFormDataError
