import * as amplitude from '@amplitude/analytics-browser'
import { useLocation } from 'react-router-dom'

const useAmplitudeTracking = () => {
  const { pathname } = useLocation()
  const trackEvent = (amplitudeEvent, eventProperties) => {
    amplitude.track(amplitudeEvent, { ...eventProperties, location: pathname })
  }

  return { trackEvent }
}
export default useAmplitudeTracking
