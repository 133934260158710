import { SET_CURRENT_ORGANIZATION, SET_AREA_UNITS } from './index'

export const setCurrentOrganization = (currentOrganization) => ({
  type: SET_CURRENT_ORGANIZATION,
  payload: currentOrganization,
})

export const setAreaUnits = (area_units) => ({
  type: SET_AREA_UNITS,
  area_units,
})
