import theme from '../assets/scss/variables.scss'

const LOCAL_COORDS = [42.31256810725995, -89.47926986999806]

export const INITIAL_VALUES = {
  latitude: LOCAL_COORDS[1],
  longitude: LOCAL_COORDS[0],
  zoom: 11,
  bearing: 0,
  pitch: 0,
}

export const APP_CONSTANTS = {
  PAGE_SIZE: 50,
  MINIMUM_PAGE_SIZE: 20,
  DEFAULT_PAGE_NUMBER: 1,
  TABLE_HEIGHT: 'calc(100vh - 290px)',
  SEARCH_INTERVAL: 500,
  // these attribute values were assigned based on previously used data used on the platform
  UNIT_SYSTEM: [
    { label: 'Imperial ', value: 'imperial' },
    { label: 'Metric', value: 'metric' },
  ],
  PERCENTAGE_RESPONSES: {
    MAX_PERCENTAGE: 100,
    MIN_PERCENTAGE: 0,
  },
}

export const FIELD_PROSPECTING = {
  TIER_TAGS: {
    TIER1: 'TIER1',
    TIER2: 'TIER2',
    TIER3: 'TIER3',
    TIER4: 'TIER4',
  },
  TIER_COLOR_CONFIG: {
    TIER1: { color: theme.lemonYellow, tier: 1 },
    TIER2: { color: theme.lightGreenColor, tier: 2 },
    TIER3: { color: theme.waterBlue, tier: 3 },
    TIER4: { color: theme.tealBlue, tier: 4 },
    DEFAULT: { color: 'default', tier: 0 },
  },
  FIELD_TYPES: [
    { value: 'rangeland', label: 'Rangeland' },
    { value: 'cropland', label: 'Cropland' },
    { value: 'pastureland', label: 'Pastureland' },
  ],
  POTENTIAL_VALUE: 30,
  FILTER_COLUMNS: {
    RATING: 'rating',
    AREA: 'area',
    TAGS: 'tags',
    FARM: 'farm',
  },
}

export const PROJECTS = {
  MASKS_CREATION_TYPES: {
    user: 'user',
  },
  MASKS_TYPES: {
    trees: 'trees',
    water: 'water',
    structure: 'structure',
    slope: 'slope',
    other: 'other',
    grassland: 'grassland',
  },
  MASKS_TYPE_COLORS: {
    trees: theme.treeGreen,
    water: theme.waterBlue,
    structure: theme.structureOrange,
    slope: theme.elevationPink,
    other: theme.otherYellow,
    grassland: theme.grasslandGreen,
  },
  MASKS_TYPE_LABELS: {
    trees: 'Tree Layer',
    water: 'Water',
    structure: 'Permanent Struc',
    slope: 'Elevation Layer',
    other: 'Other',
    grassland: 'Grasslands',
  },
  MASK_STATISTICS: {
    AUTO: ['water', 'slope'],
    MANUAL: ['structure', 'trees', 'other', 'grassland'],
  },
  FORM: {
    STEPS: ['Choose project type', 'Configure project', 'Demo'],
    // this is merely informative data, if the enum values change in the api, it will be necessary to change also in this constants section
    // todo: confirm values
    PROJECT_TYPES: [
      { label: 'Carbon Offsets: Verra', value: 'carbon_offsets' },
      { label: 'Scope 3', value: 'scope_3', disabled: true },
      { label: 'Empty Template', value: 'none', disabled: true },
    ],
    METHODOLOGY: [{ label: 'VM0042', value: 'vm0042' }],
  },
}

export const FEATURES = {
  USERS: 'Users',
  FIELDS: 'Fields',
  FIELD_SELECTION: 'FieldSelection',
  UPLOAD_FIELDS: 'UploadFields',
  DASHBOARD: 'Dashboard',
  WORK_ORDERS: 'WorkOrders',
  CROSS_ORG_UNASSIGNED_WORK_ORDER_FIELDS: 'CrossOrgUnassignedWorkOrderFields',
  PROJECT: 'Project',
}

export const PERMISSIONS = {
  LIST: 'list',
  VIEW: 'view',
  ADD: 'add',
  DELETE: 'delete',
  EDIT: 'edit',
}

export const AMPLITUDE_EVENT = {
  ELEMENT_CLICKED: 'element_clicked',
  COMPONENT_SHOWN: 'component_shown',
  DATA_CONFIRM: 'data_confirm',
}

export const TRACKED_ELEMENT = {
  BUTTON: 'button',
  MENU_BUTTON: 'menu_button',
  SELECT: 'select',
  DROPDOWN: 'dropdown',
  CHECKBOX: 'checkbox',
  MODAL: 'modal',
  TOOLTIP: 'tooltip',
  TABLE_ROW: 'table_row',
}

export const TAB_NAMES = {
  'unassigned-fields': 'unassigned-fields',
  'field-prospecting': 'field-prospecting',
  'upload-fields': 'upload-fields',
  'soc-stock': 'soc-stock',
  organizations: 'organizations',
  settings: 'settings',
  fields: 'fields',
  projects: 'projects',
  'sampling-stratification': 'sampling-stratification',
  'boundary-editor': 'boundary-editor',
}

export const TABS = [
  {
    // 1
    tab: TAB_NAMES['unassigned-fields'],
    tracking: 'sampling_ops',
    matches: ['/unassigned-fields', '/work-orders', '/sampling-stratification'],
  },
  {
    // 2
    tab: TAB_NAMES.fields,
    tracking: 'field_manager',
    matches: ['/fields'],
  },
  {
    // 3
    tab: TAB_NAMES['field-prospecting'],
    tracking: 'field_scouting',
    matches: ['/field-prospecting', '/field-prospecting/ranches'],
  },
  {
    // 4
    tab: TAB_NAMES['upload-fields'],
    tracking: 'upload_fields',
    matches: [
      '/upload-fields',
      '/soc-stock/upload-fields',
      '/upload-fields/upload-progress',
    ],
  },
  {
    // 5
    tab: TAB_NAMES.organizations,
    tracking: 'organizations',
    matches: ['/organizations'],
  },
  {
    // 6
    tab: TAB_NAMES.settings,
    tracking: 'sampling_ops',
    matches: ['/user-settings'],
  },
  {
    // soc-stock
    tab: TAB_NAMES['soc-stock'],
    matches: ['/soc-stock', '/soc-stock/ranches'],
  },
  {
    // soc-stock
    tab: TAB_NAMES['boundary-editor'],
    matches: ['/boundary-editor'],
  },
]

export const EXCLUDE_TABS = [
  {
    match: '/projects',
  },
]

export const MAPS = {
  INITIAL_LOCATION: {
    longitude: -97.609444,
    latitude: 41.519722,
    zoom: 14,
  },
  NEBRASKA_CENTERED: {
    longitude: -99.9018,
    latitude: 41.4925,
    zoom: 6,
  },
}

// 0-15, 0-30, 0-5, 5-15, 15-30

const COMMON_DEPTHS = ['0-5', '0-15', '0-30', '0-45']

const COMMON_DEPTHS_VALUES = {
  '0-15': {
    sample_depth_min_cm: 0,
    sample_depth_max_cm: 15,
    sample_type: null,
  },
  '0-30': {
    sample_depth_min_cm: 0,
    sample_depth_max_cm: 30,
    sample_type: null,
  },
  '0-5': {
    sample_depth_min_cm: 0,
    sample_depth_max_cm: 5,
    sample_type: null,
  },
  '0-45': {
    sample_depth_min_cm: 0,
    sample_depth_max_cm: 45,
    sample_type: null,
  },
}

const COMPOSITE_DEPTHS = ['0-10 Composite', '10-30 Composite']

const COMPOSITE_DEPTHS_VALUES = {
  '0-10 Composite': {
    sample_depth_min_cm: 0,
    sample_depth_max_cm: 10,
    sample_type: 'Composite',
  },
  '10-30 Composite': {
    sample_depth_min_cm: 10,
    sample_depth_max_cm: 30,
    sample_type: 'Composite',
  },
}

const SOC_BULK_DEPTHS = ['0-10 SOC + BD', '10-30 SOC + BD']

const SOC_BULK_DEPTHS_VALUES = {
  '0-10 SOC + BD': {
    sample_depth_min_cm: 0,
    sample_depth_max_cm: 10,
    sample_type: 'SOC + BD',
  },
  '10-30 SOC + BD': {
    sample_depth_min_cm: 10,
    sample_depth_max_cm: 30,
    sample_type: 'SOC + BD',
  },
}

export const SAMPLING_STRATIFICATION = {
  METHOD_TYPES: [
    { value: 'soc_stock', label: 'Cropland SOC' },
    { value: 'elevation', label: 'Elevation for rangeland' },
  ],
  DEPTHS: [...COMMON_DEPTHS, ...COMPOSITE_DEPTHS, ...SOC_BULK_DEPTHS],
  DEPTHS_VALUES: {
    ...COMMON_DEPTHS_VALUES,
    ...COMPOSITE_DEPTHS_VALUES,
    ...SOC_BULK_DEPTHS_VALUES,
  },
  PROBE_TYPES: ['2.5 cm', '5 cm', '1"', '2"'],
  PROBE_TYPES_VALUES: {
    '2.5 cm': '2.5 cm',
    '5 cm': '5 cm',
    '1"': '1 inch',
    '2"': '2 inch',
  },
}

export const SAMPLING_OPS_TABS = {
  samplingStratification: 'sampling-stratification',
  unassignedFields: 'unassigned-fields',
  workOrders: 'work-orders',
}

export const WO_DETAILS_TABS = {
  fields: 'fields',
  details: 'details',
}

export const STRATIFICATION_STATUS = {
  success: 'success',
  initialized: 'initialized',
  running: 'running',
  error: 'error',
}

export const USER_ROLES = {
  organizationAdmin: 'Organization Admin',
  sampler: 'Sampler',
  superAdmin: 'Super Admin',
  organizationMember: 'Organization Member',
}

export const SOC_STOCK_L2 = {
  SOC_STOCK_PER_AREA: {
    MAX_VALUE: 135,
    MIN_VALUE: 1,
  },
  COLOR_SCALE: [theme.otherYellow, theme.limitSOCTons],
}

export const FILE_PROCESSING_STATUS = {
  SUCCESS: 'success',
  PARTIAL_FAILURE: 'partialFailure',
  FAILURE: 'failure',
}

export const HEADER_LINKS = {
  'field-prospecting': {
    label: 'Field Prospecting',
    matches: ['field-prospecting'],
  },
  'boundary-editor': {
    label: 'Boundary Editor',
    matches: ['boundary-editor'],
  },
  'soc-stock': {
    label: 'SOC Stock L2',
    matches: ['soc-stock'],
  },
  'unassigned-fields': {
    label: 'Sampling Ops',
    matches: [...Object.values(SAMPLING_OPS_TABS)],
  },
}

export const TABS_CONFIG = [
  {
    path: SAMPLING_OPS_TABS.samplingStratification,
    label: 'Sample Planning',
    position: 'first',
  },
  {
    path: SAMPLING_OPS_TABS.unassignedFields,
    label: 'Create Work Order',
  },
  {
    path: SAMPLING_OPS_TABS.workOrders,
    label: 'Monitor',
    position: 'last',
  },
]

export const DEFAULT_RATING_TIERS_IN_CO2E_TONS_PER_HECTARE_PER_YEAR = {
  TIER1: [1.9768, null],
  TIER2: [1.2355, 1.9768],
  TIER3: [0.7413, 1.2355],
  TIER4: [0, 0.7413],
}

export const MINIMUM_NUMBER_OF_VERTICES = 3

export const COLLAPSIBLE_DATA_GRID = {
  OPEN_DEFAULT_WIDTH: 400,
  CLOSED_DEFAULT_WIDTH: 0,
  TOGGLE_ANIMATION_DURATION_MS: 500,
  OPEN_MIN_WIDTH: 300,
  CLOSED_MIN_WIDTH: 0,
  OPEN_BUTTON_POSITION: 10,
  CLOSED_BUTTON_POSITION: 30,
}
