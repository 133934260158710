import { combineReducers } from 'redux'
import user from './user'
import organization from './organization'
import role from './role'
import permissions from './permissions'
import localization from './localization'
import dashboard from './dashboard'
import workOrders from './workOrders'
import projects from './projects'
import fieldTransactions from './fieldTransactions'
import boundaries from './boundaries'
import fields from './fields'
import ranches from './ranches'
import appState from './appState'
import stratificationParameters from './stratificationParameters'
import masks from './masks'

export const RESET_ALL = 'RESET_ALL'

const appReducer = combineReducers({
  user,
  organization,
  role,
  permissions,
  localization,
  dashboard,
  workOrders,
  projects,
  fieldTransactions,
  boundaries,
  fields,
  ranches,
  appState,
  stratificationParameters,
  masks,
})

const rootReducer = (state, action) => {
  if (action.type === RESET_ALL) {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer
