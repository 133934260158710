// Constants for action types
export const ADD_STATUS_TO_LIST = 'fieldTransactions/ADD_STATUS_TO_LIST'
export const UPDATE_STATUS_IN_LIST = 'fieldTransactions/UPDATE_STATUS_IN_LIST'
export const REMOVE_STATUS_FROM_LIST =
  'fieldTransactions/REMOVE_STATUS_FROM_LIST'
export const CLEAR_TRANSACTION_LIST = 'fieldTransactions/CLEAR_TRANSACTION_LIST'
// Initial state
const initialState = {
  uploadList: [],
  lastChanged: '',
  transactionList: [],
}

// Reducer function
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_STATUS_TO_LIST: {
      if (!state.uploadList) state = initialState

      return {
        ...state,
        uploadList: [...state.uploadList, action.payload],
        lastChanged: action.payload.uploadId,
      }
    }
    case UPDATE_STATUS_IN_LIST: {
      const updatedList = state.uploadList.map((item) =>
        item.uploadId === action.payload.uploadId
          ? {
              ...item,
              result: action.payload.result,
              retries: action.payload.retries,
            }
          : item
      )
      return {
        ...state,
        uploadList: updatedList,
        lastChanged: action.payload.uploadId,
      }
    }
    case REMOVE_STATUS_FROM_LIST: {
      const filteredList = state.uploadList.filter(
        (status) => status.uploadId !== action.payload.uploadId
      )
      const transaction = state.uploadList.find(
        (status) => status.uploadId == action.payload.uploadId
      )
      const newTransaction = {
        fileName: transaction.fileName,
        error: action.payload.errorResult,
      }

      return {
        ...state,
        uploadList: filteredList,
        lastChanged: '',
        transactionList: [...state.transactionList, newTransaction],
      }
    }
    case CLEAR_TRANSACTION_LIST: {
      return {
        ...state,
        transactionList: [],
      }
    }

    default:
      return state
  }
}

export default reducer
