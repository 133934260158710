export const FETCH_ROLES_REQUEST = 'FETCH_ROLES_REQUEST'
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS'
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE'

const initialState = {
  loading: false,
  roles: [],
  error: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload,
        error: false,
      }
    case FETCH_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        roles: [],
        error: action.payload,
      }
    default:
      return state
  }
}

export default reducer
