import { SET_PERMISSIONS } from './index'
import { APIS } from '../../services'

export const fetchUserPermissions = (organization_id) => (dispatch) => {
  if (organization_id && organization_id !== 'undefined') {
    return APIS.getPermissions({ organization_id }).then((res) => {
      dispatch({
        type: SET_PERMISSIONS,
        permissions: res.result,
      })
    })
  }
}
