import { omit } from 'lodash'
export const SET_BOUNDARY_ATTR = 'boundary/SET_BOUNDARY_ATTR'
export const BOUNDARY_RESET = 'boundary/BOUNDARY_RESET'

export const EDITING_TYPE = {
  field: 'field',
  mask: 'mask',
}

const initialState = {
  editingType: null,
  isEditing: false,
  isCreating: false,
  isRemoving: false,
  creatingType: null,
  boundary: {},
  dirtyChanges: false,
  boundaryToDelete: {},
  boundaryToUpdate: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOUNDARY_ATTR:
      return {
        ...state,
        ...omit(action, 'type'),
      }

    case BOUNDARY_RESET:
      return { ...initialState }
    default:
      return state
  }
}

export default reducer
