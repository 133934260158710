import * as Sentry from '@sentry/react'

export const client = Sentry.init({
  // Enable if REACT_APP_SENTRY_DSN_UI is set
  enabled: Boolean(process.env.REACT_APP_SENTRY_DSN_UI),
  dsn: process.env.REACT_APP_SENTRY_DSN_UI,
  environment: process.env.REACT_APP_ENV,
  release: `${process.env.REACT_APP_ENV}-${process.env.REACT_APP_RELEASE}`, // REACT_APP_RELEASE is actually CI_COMMIT_SHA (see package.json). Must match release in scripts/uploadSourceMaps.js
  integrations: [
    Sentry.browserTracingIntegration({
      // We set up our own tooling to create spans around page navigation
      // The built-in support for ReactRouter v5 doesn't appear to work
      // See https://docs.sentry.io/platforms/javascript/tracing/instrumentation/automatic-instrumentation/#custom-routing
      instrumentNavigation: false,
      instrumentPageLoad: false,
      // Sentry can wrap slow renders in spans
      // This might be useful, but for our purposes creates too many spans
      enableLongAnimationFrame: false,
      enableInp: false,
      // Only trace http requests to the terra api
      shouldCreateSpanForRequest(url) {
        return url.match(/(localhost|api\.perennial\.earth)/)
      },
    }),
    Sentry.replayIntegration(),
  ],

  beforeSendTransaction(event) {
    // Exclude some spans we don't care about enough to pay for
    event.spans = event.spans.filter((span) => {
      return (
        // These spans describe server-rendered page load times
        // and UI paint times. We might want these later, but for now
        // it's too many spans
        !span.origin.startsWith('auto.ui') &&
        !span.op.startsWith('resource') &&
        !span.op.startsWith('mark') &&
        !span.op.startsWith('paint')
      )
    })
    return event
  },
  tracesSampleRate:
    'REACT_APP_SENTRY_TRACES_SAMPLE_RATE' in process.env
      ? parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
      : 1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  // Send trace headers to these urls, only (terra API server)
  tracePropagationTargets: ['localhost', /^https:\/\/.*\.perennial.earth/],
})
