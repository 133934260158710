import './style.scss'
import React from 'react'
import { Stepper, Step, StepLabel, Typography } from '@mui/material'

import theme from '../../assets/scss/variables.scss'

export const SimpleStepper = ({ steps, activeStep, ...props }) => {
  return (
    // use of Stepper componment from Material UI
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      connector={null}
      {...props}
    >
      {/* We can pass all the steps to the current stepper, this component will render all the steps with customs styles */}
      {steps.map((step, index) => (
        <Step
          key={step}
          className="customstepper-step"
          sx={{
            backgroundColor:
              activeStep === index ? theme.project0 : 'transparent',
            overflow: 'hidden',
            paddingRight: '10px',
          }}
        >
          <StepLabel
            StepIconComponent={() => (
              <Typography className="customstepper-step-counter-label">
                {/* this line is the default counter subsitute of material ui*/}
                {index + 1}.
              </Typography>
            )}
          >
            <Typography className="customstepper-step-label">{step}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
