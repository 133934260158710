import React from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import theme from '../../assets/scss/variables.scss'

const Spinner = styled(Spin)`
  position: relative;
  top: 50% !important;
  left: 50% !important;
  height: 60px !important;
  width: 200px !important;
  background: rgba(${theme.blackColor}, 0.3) !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 5px !important;
  padding: 10px !important;
  div,
  span {
    color: #fff;
    margin: 0 5px !important;
    position: relative !important;
    top: initial !important;
    width: initial !important;
    left: initial !important;
  }
  div {
    display: inline-block !important;
    font-size: 20px;
  }
`
const antIcon = <Loading3QuartersOutlined style={{ fontSize: 20 }} spin />
// The purpose of this spinner is to be used as a loader on maps, it has to be changed to a MUI component
const Loading = ({ loading }) => (
  <Spinner
    className="spinner-container"
    spinning={loading}
    indicator={antIcon}
    tip="Loading..."
  />
)

export default Loading
