import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import {
  startFetchingProjects as startFetchingProjects_,
  startFetchingSingleProject as startFetchingSingleProject_,
  startFetchingMasks as startFetchingMasks_,
  fetchProjects as fetchProjects_,
  fetchProject as fetchProject_,
  fetchMasks as fetchMasks_,
  resetFetchingMasks as resetFetchingMasks_,
  setSelectedFieldId as setSelectedFieldId_,
  setSelectedMaskId as setSelectedMaskId_,
  setNewFieldBoundary as setNewFieldBoundary_,
  setNewMaskBoundary as setNewMaskBoundary_,
  pushNewMask as pushNewMask_,
  setNewMaskShape as setNewMaskShape_,
  setNewFields as setNewFields_,
  setFormAttr as setFormAttr_,
  resetForm as resetForm_,
} from '../redux/projects/actions'
import { projectFormStep as projectFormStep_ } from '../redux/projects/selectors'

export const useProjectUtilities = () => {
  const dispatch = useDispatch()
  const {
    currentOrganization: { id },
  } = useSelector((state) => state.organization)
  const {
    inFlight,
    requestId,
    projectList,
    selectedProject,
    singleRequestId,
    masksRequestId,
    masks,
    fieldSelectedId,
    maskSelectedId,
    newMaskShape,
    fields,
  } = useSelector((state) => state.projects)

  return {
    fetchProjects: async () => {
      const requestId = uuid()
      dispatch(startFetchingProjects_({ requestId }))
      dispatch(await fetchProjects_({ organization_id: id, requestId }))
    },
    fetchProject: async ({ project_id }) => {
      const singleRequestId = uuid()
      dispatch(startFetchingSingleProject_({ singleRequestId }))
      dispatch(
        await fetchProject_({
          organization_id: id,
          project_id,
          singleRequestId,
        })
      )
    },
    fetchMasks: async ({ ag_field_uuid }) => {
      const masksRequestId = uuid()
      dispatch(startFetchingMasks_({ masksRequestId }))
      if (!ag_field_uuid) {
        dispatch(resetFetchingMasks_({ masksRequestId }))
      } else {
        dispatch(
          await fetchMasks_({
            organization_id: id,
            ag_field_uuid,
            masksRequestId,
          })
        )
      }
    },
    setSelectedFieldId: (value) => dispatch(setSelectedFieldId_(value)),
    setSelectedMaskId: (value) => dispatch(setSelectedMaskId_(value)),
    setNewFieldBoundary: (value) => dispatch(setNewFieldBoundary_(value)),
    setNewMaskBoundary: (value) => dispatch(setNewMaskBoundary_(value)),
    pushNewMask: (value) => dispatch(pushNewMask_(value)),
    setNewMaskShape: (value) => dispatch(setNewMaskShape_(value)),
    setNewFields: (value) => dispatch(setNewFields_(value)),
    inFlight,
    requestId,
    projectList,
    selectedProject,
    singleRequestId,
    masksRequestId,
    masks,
    fieldSelectedId,
    maskSelectedId,
    newMaskShape,
    fields,
  }
}

export const useProjectFormUtilities = () => {
  const dispatch = useDispatch()
  const projectFormStep = useSelector(projectFormStep_)

  return {
    resetForm: () => dispatch(resetForm_()),
    setFormType: ({ projectType }) => dispatch(setFormAttr_({ projectType })),
    setConfigForm: (values) => dispatch(setFormAttr_(values)),
    setLoading: ({ loading }) => dispatch(setFormAttr_({ loading })),
    increaseStep: () => {
      if (projectFormStep === null) {
        dispatch(setFormAttr_({ step: 0 }))
      } else {
        dispatch(setFormAttr_({ step: (projectFormStep || 0) + 1 }))
      }
    },
    reduceStep: () => {
      if (projectFormStep <= 0) {
        dispatch(setFormAttr_({ step: null }))
      } else {
        dispatch(setFormAttr_({ step: (projectFormStep || 1) - 1 }))
      }
    },
  }
}
