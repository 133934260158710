import React, { useState } from 'react'
import { Popover, Menu, Checkbox } from 'antd'
import { BlockOutlined } from '@ant-design/icons'

const ColumnSelector = ({ columns, setColumns }) => {
  const [selectedValues, setSelectedValues] = useState(
    columns.map((col) => col.visible && col.title)
  )

  const onChange = (values) => {
    setSelectedValues(values)
    const newColumns = columns.map((col) => {
      col.visible = values.includes(col.title)
      return col
    })
    setColumns(newColumns)
  }
  const menu = (
    <Checkbox.Group
      style={{ width: '100%' }}
      onChange={onChange}
      value={selectedValues}
    >
      <Menu className="column-chooser">
        {columns &&
          columns.map((colummn) => (
            <Menu.Item key={colummn.title}>
              <Checkbox disabled={!colummn.selectable} value={colummn.title}>
                {colummn.title}
              </Checkbox>
            </Menu.Item>
          ))}
      </Menu>
    </Checkbox.Group>
  )
  return (
    <Popover placement="bottomRight" content={menu} trigger="click">
      <BlockOutlined className="tool-icon" />
    </Popover>
  )
}

export default ColumnSelector
