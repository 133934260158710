import { omit } from 'lodash'

import { APP_CONSTANTS } from '../../constants'
import { parsePaginationFieldData, parseTotalCount } from '../../utils/fields'

export const SET_WORK_ORDERS = 'workOrders/SET_WORK_ORDERS'
export const SET_UNASSIGNED_FIELDS = 'workOrders/SET_UNASSIGNED_FIELDS'
export const SET_DETAIL_WORK_ORDER = 'workOrders/SET_DETAIL_WORK_ORDER'
export const START_LOAD_WORK_ORDERS = 'workOrders/START_LOAD_WORK_ORDERS'
export const START_LOAD_DETAIL_WORK_ORDER =
  'workOrders/START_LOAD_DETAIL_WORK_ORDER'
export const START_LOAD_UNASSIGNED_FIELDS =
  'workOrders/START_LOAD_UNASSIGNED_FIELDS'
export const START_LOAD_UNASSIGNED_FIELDS_PAGINATED =
  'workOrders/START_LOAD_UNASSIGNED_FIELDS_PAGINATED'
export const SET_UNASSIGNED_FIELDS_PAGINATED =
  'workOrders/SET_UNASSIGNED_FIELDS_PAGINATED'
export const RESET_UNASSIGNED_FIELDS = 'workOrders/RESET_UNASSIGNED_FIELDS'
export const SET_SELECTED_UNASSIGNED_FIELDS =
  'workOrders/SET_SELECTED_UNASSIGNED_FIELDS'
export const SET_LAST_SELECTED_UNASSIGNED_FIELD =
  'workOrders/SET_LAST_SELECTED_UNASSIGNED_FIELD'
export const SET_WORK_ORDERS_ATTR = 'workOrders/SET_WORK_ORDERS_ATTR'
export const RESET_WORK_ORDERS = 'workOrders/RESET_WORK_ORDERS'
export const SET_WORK_ORDERS_PAGINATED = 'workOrders/SET_WORK_ORDERS_PAGINATED'
export const RESET_WORK_ORDER_DETAILS = 'workOrders/RESET_WORK_ORDER_DETAILS'
export const SET_WORK_ORDERS_STATE_ATTR =
  'workOrders/SET_WORK_ORDERS_STATE_ATTR'
export const SET_UNASSIGNED_WORK_ORDERS_ATTR =
  'workOrders/SET_UNASSIGNED_WORK_ORDERS_ATTR'

const initialState = {
  workOrders: {
    totalCount: 0,
    data: [],
    loading: false,
    page: 1,
    limit: APP_CONSTANTS.MINIMUM_PAGE_SIZE,
    availableData: true,
  },
  selectedWorkOrderFields: [],
  selectedWorkOrders: [],
  workOrderFieldSelected: {},
  detailWorkOrders: {
    loading: false,
  },
  unAssignedFields: {
    totalCount: 0,
    isUnassigned: true,
    data: [],
    loading: false,
    page: 1,
    limit: APP_CONSTANTS.PAGE_SIZE,
    availableData: true,
    selectedFields: [],
    lastFieldSelected: {},
    selectedUnassignedField: {},
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WORK_ORDERS_STATE_ATTR:
      return {
        ...state,
        ...omit(action, 'type'),
      }
    case START_LOAD_WORK_ORDERS:
      return {
        ...state,
        workOrders: {
          totalCount: 0,
          data: [],
          loading: true,
        },
      }
    case START_LOAD_DETAIL_WORK_ORDER:
      return {
        ...state,
        detailWorkOrders: {
          loading: true,
        },
      }
    case START_LOAD_UNASSIGNED_FIELDS:
      return {
        ...state,
        unAssignedFields: {
          totalCount: 0,
          data: [],
          loading: true,
          page: 1,
          limit: APP_CONSTANTS.PAGE_SIZE,
        },
      }
    case SET_WORK_ORDERS:
      return {
        ...state,
        workOrders: {
          totalCount: action.totalCount,
          data: action.data,
          loading: false,
        },
      }
    case SET_UNASSIGNED_FIELDS:
      return {
        ...state,
        unAssignedFields: {
          totalCount: action.totalCount,
          data: action.data,
          loading: false,
        },
      }
    case SET_DETAIL_WORK_ORDER:
      return {
        ...state,
        detailWorkOrders: {
          ...state.detailWorkOrders,
          [action.workOrderId]: {
            data: action.data,
            totalCount: action.totalCount,
            assignedToFirstName: action.assignedToFirstName || '',
            assignedToLastName: action.assignedToLastName || '',
            name: action.name,
            samplingProgress: action.samplingProgress,
            samplingTotal: action.samplingTotal,
            workOrderId: action.workOrderId,
            updatedAt: action.updatedAt,
            isUnassigned: action.isUnassigned,
            assignedUserId: action.assignedUserId,
            workOrderBbox: action.workOrderBbox,
          },
          loading: false,
        },
      }
    // this pagination works with scroll integration
    case SET_UNASSIGNED_FIELDS_PAGINATED:
      return {
        ...state,
        unAssignedFields: {
          ...state.unAssignedFields,
          totalCount: parseTotalCount(
            action.totalCount,
            state.unAssignedFields.totalCount
          ),
          data: parsePaginationFieldData(
            action.data || [],
            state.unAssignedFields.data,
            action.page,
            'fieldId'
          ),
          loading: false,
          page: action.page,
          availableData: action.availableData,
        },
      }
    case START_LOAD_UNASSIGNED_FIELDS_PAGINATED:
      return {
        ...state,
        unAssignedFields: {
          ...state.unAssignedFields,
          loading: true,
        },
      }
    case SET_SELECTED_UNASSIGNED_FIELDS:
      return {
        ...state,
        unAssignedFields: {
          ...state.unAssignedFields,
          selectedFields: [...action.selectedFields],
        },
      }
    case SET_LAST_SELECTED_UNASSIGNED_FIELD:
      return {
        ...state,
        unAssignedFields: {
          ...state.unAssignedFields,
          lastFieldSelected: { ...action.lastFieldSelected },
        },
      }
    case RESET_UNASSIGNED_FIELDS:
      return {
        ...state,
        unAssignedFields: { ...initialState.unAssignedFields },
      }
    case SET_WORK_ORDERS_PAGINATED:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          totalCount: parseTotalCount(
            action.totalCount,
            state.workOrders.totalCount
          ),
          data: parsePaginationFieldData(
            action.data || [],
            state.workOrders.data,
            action.page,
            'workOrderId'
          ),
          loading: false,
          page: action.page,
          availableData: action.availableData,
        },
      }
    case SET_WORK_ORDERS_ATTR:
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          ...omit(action, 'type'),
        },
      }
    case RESET_WORK_ORDERS:
      return {
        ...state,
        workOrders: { ...initialState.workOrders },
        selectedWorkOrders: initialState.selectedWorkOrders,
        workOrderFieldSelected: initialState.workOrderFieldSelected,
        detailWorkOrders: initialState.detailWorkOrders,
      }
    case RESET_WORK_ORDER_DETAILS:
      return {
        ...state,
        detailWorkOrders: { ...initialState.detailWorkOrders },
      }
    case SET_UNASSIGNED_WORK_ORDERS_ATTR:
      return {
        ...state,
        unAssignedFields: {
          ...state.unAssignedFields,
          ...omit(action, 'type'),
        },
      }
    default:
      return state
  }
}

export default reducer
