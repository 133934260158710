import React from 'react'
import { AppBar, Button, Toolbar, Typography, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link, useLocation } from 'react-router-dom'
import theme from '../../assets/scss/variables.scss'
import { Flagged } from '../Flagged'
import { useProjectUtilities } from '../../hooks/useProjectUtilities'
import { getOrgScopedName, isProjectPath } from '../../utils'
import { HEADER_LINKS } from '../../constants'

const { project0, project1, project2, project3, project4 } = theme

const HeaderAppBar = styled(AppBar)({
  background: theme.blackColor,
  height: 64,
  justifyContent: 'center',
})

const HeaderButton = styled(Button)(({ active }) => ({
  color: active ? theme.mainBlackColor : theme.whiteColor,
  backgroundColor: active ? theme.lightMint : 'transparent',
  margin: '0 10px !important',
  borderRadius: '4px',
  padding: '2px 30px',
  minWidth: '50px !important',
  '&:hover': {
    backgroundColor: theme.lightMint,
    color: theme.mainBlackColor,
  },
}))

const HeaderTitle = styled(Typography)({
  color: '#FFF',
  marginRight: '20px !important',
  width: '20%',
})

const Circle = styled('div')({
  width: '15px',
  height: '15px',
  borderRadius: '50%',
  marginRight: '10px',
})

const colors = [project0, project1, project2, project3, project4]

const Header = () => {
  const { pathname } = useLocation()
  const { selectedProject = {}, projectList } = useProjectUtilities()

  const projectIndex = projectList.findIndex(
    (project) => project.id === selectedProject.id
  )

  const isWorkspaceScoped = isProjectPath(pathname)

  const dynamicName = () => {
    if (isWorkspaceScoped) {
      return selectedProject.name || ''
    } else {
      return getOrgScopedName(pathname)
    }
  }

  const isActive = (matches) => {
    return matches.some((match) => pathname.includes(match))
  }

  const headerTitle = (
    <HeaderTitle noWrap variant="h6">
      {dynamicName()}
    </HeaderTitle>
  )

  return (
    <HeaderAppBar position="static" style={{ background: theme.black10 }}>
      <Toolbar>
        <Flagged visible={isWorkspaceScoped}>
          <Circle style={{ background: colors[projectIndex] || project4 }} />
        </Flagged>
        {isWorkspaceScoped ? (
          <Tooltip title={dynamicName()} placement="bottom" arrow>
            {headerTitle}
          </Tooltip>
        ) : (
          headerTitle
        )}
        <Flagged visible={isWorkspaceScoped}>
          {Object.entries(HEADER_LINKS).map(([route, { label, matches }]) => (
            <Link key={route} to={`/projects/${selectedProject.id}/${route}`}>
              <HeaderButton active={isActive(matches)}>{label}</HeaderButton>
            </Link>
          ))}
        </Flagged>
      </Toolbar>
    </HeaderAppBar>
  )
}

export default Header
