import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchCurrentUser } from '../redux/user/actions'
import styled from 'styled-components'
import LoadingBackdrop from '../components/LoadingBackdrop/LoadingBackdrop'

export const LoadContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const withAuthentication = (Component) => (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { identity } = useSelector((state) => state.user)
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (identity) {
        setInitialized(false)
        await dispatch(fetchCurrentUser(history))
        setInitialized(true)
      } else {
        history.push('/login')
      }
    }
    fetchData()
  }, [identity])

  return initialized ? (
    <Component {...props} />
  ) : (
    <LoadContainer>
      <LoadingBackdrop loading />
    </LoadContainer>
  )
}

export default withAuthentication
