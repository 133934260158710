import { SET_CURRENT_ORGANIZATION } from '../organization'
import { omit } from 'lodash'
export const SET_DASHBOARD_ATTR = 'dashboard/SET_DASHBOARD_ATTR'
export const RESULTS = 'dashboard/RESULTS'

const initialState = {
  page: 1,
  limit: 50,
  sort_by: '',
  sort_order: null,
  searchText: '',
  fields: [],
  inFlight: false,
  hasChanged: false,
  isComplete: false,
  requestId: null,
  area_units: null,
  hideNullStockFields: true,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_ATTR:
      return {
        ...state,
        ...omit(action, 'type'),
      }
    case RESULTS:
      // the request id check is for slow debounces
      return state.requestId === action.requestId
        ? {
            ...state,
            fields: action.fields,
            inFlight: false,
            isComplete: true,
            requestId: initialState.requestId,
            area_units: action.area_units,
            hide_null: action.hideNullStockFields,
          }
        : state
    case SET_CURRENT_ORGANIZATION:
      return initialState
    default:
      return state
  }
}

export default reducer
