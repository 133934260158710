import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { EXCLUDE_TABS, TABS, SAMPLING_OPS_TABS } from '../constants'
import { setSelectedItem as setSelectedItem_ } from '../redux/appState/action'

const getTabFound = (arr = [], str = '') => {
  return arr.includes(str)
}

export const useTabHandler = () => {
  const {
    location: { pathname },
  } = useHistory()
  const { selectedItem } = useSelector((state) => state.appState)
  const dispatch = useDispatch()

  useEffect(() => {
    const currentTab = TABS.find((tab) => getTabFound(tab.matches, pathname))
    const exclude = EXCLUDE_TABS.find((tab) => pathname?.includes(tab.match))

    if (exclude) {
      dispatch(setSelectedItem_({ selectedItem: null }))
    } else if (currentTab) {
      dispatch(setSelectedItem_({ selectedItem: currentTab.tab }))
    }
  }, [pathname])

  return {
    setSelectedItem: ({ tab }) =>
      dispatch(setSelectedItem_({ selectedItem: tab })),
    selectedItem,
  }
}

export const useSamplingOpsTabs = () => {
  const {
    location: { pathname },
  } = useHistory()

  const currentTab = useMemo(() => {
    return (
      Object.entries(SAMPLING_OPS_TABS).find(([, value]) =>
        pathname.includes(value)
      )?.[1] || SAMPLING_OPS_TABS.samplingStratification
    )
  }, [pathname])

  return { currentTab }
}
