import React from 'react'
import { isAdmin as _isAdmin } from '../selectors'
import { useSelector } from 'react-redux'

const withAdminRequired = (Component) => (props) => {
  const isAdmin = useSelector(_isAdmin)
  return isAdmin ? (
    <Component {...props} />
  ) : (
    <div>Administrator Access Required</div>
  )
}

export default withAdminRequired
