import { createSelector } from 'reselect'

const projectsSelector = (state) => state.projects
export const projectsFields = createSelector(
  projectsSelector,
  (ps) => ps?.fields
)
export const selectedFieldId = createSelector(
  projectsSelector,
  (ps) => ps?.fieldSelectedId
)
export const selectedField = createSelector(
  projectsFields,
  selectedFieldId,
  (fields, id) => fields?.find(({ ag_field_uuid }) => ag_field_uuid === id)
)
export const projectsMasks = createSelector(projectsSelector, (ps) => ps?.masks)
export const selectedMaskId = createSelector(
  projectsSelector,
  (ps) => ps?.maskSelectedId
)
export const selectedMask = createSelector(
  projectsMasks,
  selectedMaskId,
  (masks, selectedId) => masks?.find(({ id }) => id === selectedId)
)

export const projectForm = createSelector(projectsSelector, (ps) => ps?.form)

export const projectFormStep = createSelector(
  projectForm,
  (fs) => fs?.step ?? null
)

export const projectFormType = createSelector(
  projectForm,
  (fs) => fs?.projectType
)
