import React from 'react'
import { styled } from '@mui/material/styles'
import {
  Drawer as MuiDrawer,
  ListItemButton,
  ListItemIcon,
} from '@mui/material'

const drawerWidth = 250

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  [theme.breakpoints.down('md')]: {
    width: `calc(${theme.spacing(9.5)} + 1px)`,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: `calc(${theme.spacing(9.5)} + 1px)`,
  },
  [theme.breakpoints.up('lg')]: {
    width: `calc(${theme.spacing(11.1)} + 1px)`,
  },
  [theme.breakpoints.up('xl')]: {
    width: `calc(${theme.spacing(11.1)} + 1px)`,
  },
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export const MuiListItemButton = ({
  selected,
  onClick,
  children,
  open,
  icon,
}) => {
  return (
    <ListItemButton
      button="true"
      selected={selected}
      onClick={onClick}
      sx={{
        justifyContent: open ? 'initial' : 'center',
        px: 2.5,
      }}
    >
      {icon && (
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {icon}
        </ListItemIcon>
      )}
      {children}
    </ListItemButton>
  )
}
export default Drawer
