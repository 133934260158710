import { SOC_STOCK_L2 } from '../constants/app_constants'
import { hexToRgb } from './common'
import theme from '../assets/scss/variables.scss'

/**
 * Returns the color value for a given SOC stock value.
 *
 * @param {number} value - The SOC stock value.
 * @return {string} The color value in RGB format, or ''rgb(211, 211, 211)' if the value is falsy.
 */

const getSocStockFieldColor = (value) => {
  if (value === null || value === undefined || isNaN(value)) {
    return theme.greyColor40
  }

  const rgb1 = hexToRgb(SOC_STOCK_L2.COLOR_SCALE[0])
  const rgb2 = hexToRgb(SOC_STOCK_L2.COLOR_SCALE[1])

  const normalizedValue =
    (value - SOC_STOCK_L2.SOC_STOCK_PER_AREA.MIN_VALUE) /
    (SOC_STOCK_L2.SOC_STOCK_PER_AREA.MAX_VALUE -
      SOC_STOCK_L2.SOC_STOCK_PER_AREA.MIN_VALUE)

  const [r1, g1, b1] = rgb1
  const [r2, g2, b2] = rgb2

  const r = Math.round(r1 + (r2 - r1) * normalizedValue)
  const g = Math.round(g1 + (g2 - g1) * normalizedValue)
  const b = Math.round(b1 + (b2 - b1) * normalizedValue)

  return `rgb(${r},${g},${b})`
}

export default getSocStockFieldColor
