import * as Sentry from '@sentry/react'
import { useHistory } from 'react-router-dom'
import React, { useEffect } from 'react'
import { client } from '../services/sentry'

// Create the top-level navigation span right away
let pageLoadSpan = Sentry.startBrowserTracingPageLoadSpan(client, {
  name: window.location.pathname,
  attributes: {
    [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'url',
  },
})

/**
 * Creates spans in sentry for each page navigation
 *
 * See https://docs.sentry.io/platforms/javascript/tracing/instrumentation/automatic-instrumentation/#custom-routing
 */
const SentryRouteListener = () => {
  const history = useHistory()

  useEffect(() => {
    return history.listen((location) => {
      // Make sure that the pageload span uses the route name
      // After that, each route change should trigger a navigation span (which will automatically finish the previous one)
      if (pageLoadSpan) {
        pageLoadSpan.updateName(location.pathname)
        pageLoadSpan.setAttribute(
          Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE,
          'route'
        )
        pageLoadSpan = undefined
      } else {
        console.log('ROUTE', location.pathname)
        Sentry.startBrowserTracingNavigationSpan(client, {
          op: 'navigation',
          name: location.pathname, // or what the name of the span should be
          attributes: {
            [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'route',
          },
        })
      }
    })
  }, [history])

  return <></>
}

export default SentryRouteListener
