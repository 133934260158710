import React from 'react'
import {
  FormControlLabel,
  Radio,
  Typography,
  useRadioGroup,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import theme from '../../assets/scss/variables.scss'

const CustomFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ checked, disabled }) => ({
  width: '125px',
  height: '88px',
  borderRadius: '12px',
  borderWidth: '1px',
  borderStyle: 'solid',
  alignItems: 'flex-start',
  borderColor: checked
    ? theme.project0
    : disabled
    ? theme.greyColor
    : theme.black,
}))

// there are some basic differences with a normal radio button,
// the first one is that it makes use of the material UI components,
// this gives us a container to which we can add custom styles, also,
// we can access from child components to the selected value of the radio button
export const SimpleRadio = ({ label, value, disabled }) => {
  const radioGroup = useRadioGroup()

  return (
    <CustomFormControlLabel
      checked={radioGroup.value === value}
      value={value}
      labelPlacement="bottom"
      disabled={disabled}
      control={
        <Radio
          sx={{
            color: theme.black,
            '&.Mui-checked': {
              color: theme.project0,
            },
          }}
        />
      }
      label={
        <Typography
          marginLeft="10px"
          fontSize="12px"
          marginTop="auto"
          marginBottom="auto"
          color={disabled ? theme.grey82 : theme.black}
        >
          {label}
        </Typography>
      }
    />
  )
}
