import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserPermissions } from '../redux/permissions/actions'
import { fetchRoles } from '../redux/role/actions'
import { LoadContainer } from './withAuthentication'
import { setAreaUnits } from '../redux/organization/actions'
import LoadingBackdrop from '../components/LoadingBackdrop/LoadingBackdrop'

const withOrganizationBinding = (Component) =>
  React.memo((props) => {
    const [initialized, setInitialized] = useState(false)
    const dispatch = useDispatch()
    const { currentOrganization } = useSelector((state) => state.organization)
    const { identity } = useSelector((state) => state.user)

    useEffect(() => {
      const fetchData = async () => {
        setInitialized(false)
        await Promise.all([
          dispatch(fetchUserPermissions(currentOrganization.id)),
          dispatch(fetchRoles(currentOrganization.id)),
        ])
        setInitialized(true)
        dispatch(setAreaUnits(currentOrganization.unit_system || 'imperial'))
      }

      if (identity && currentOrganization.id) {
        fetchData()
      } else {
        setInitialized(true)
      }
    }, [identity, currentOrganization.id, dispatch])

    return initialized ? (
      <Component {...props} />
    ) : (
      <LoadContainer>
        <LoadingBackdrop loading />
      </LoadContainer>
    )
  })

export default withOrganizationBinding
