import React from 'react'
import { Amplify } from 'aws-amplify'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { start as startMSW } from './mocks/browser'
import { AWSConf } from './constants'
import { ThemeProvider } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-license-pro'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import theme from './mui/themeprovider'

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: AWSConf.cognito.REGION,
    userPoolId: AWSConf.cognito.USER_POOL_ID,
    userPoolWebClientId: AWSConf.cognito.APP_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_REDIRECT_DOMAIN_NAME,
      redirectSignIn: `${window.location.href}`,
      redirectSignOut: `${process.env.REACT_APP_HOST_PATH}/login`,
      responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  Analytics: {
    disabled: true,
  },
  Storage: {
    region: AWSConf.s3.REGION,
    bucket: AWSConf.s3.BUCKET,
  },
  API: {
    endpoints: [
      {
        name: 'cloudag',
        endpoint: AWSConf.apiGateway.URL,
        region: AWSConf.apiGateway.REGION,
      },
    ],
  },
})

if (process.env.REACT_APP_MUI_X_PRO_KEY) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_KEY)
}

startMSW().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <App />
        </LocalizationProvider>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
})

reportWebVitals()
