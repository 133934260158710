import {
  FETCH_ROLES_FAILURE,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
} from './index'

import { APIS } from '../../services'

export const fetchRolesRequest = () => ({
  type: FETCH_ROLES_REQUEST,
})

export const fetchRolesSuccess = (roles) => ({
  type: FETCH_ROLES_SUCCESS,
  payload: roles,
})

export const fetchRolesFailure = (error) => ({
  type: FETCH_ROLES_FAILURE,
  payload: error,
})

export const fetchRoles = (organization_id) => (dispatch) => {
  if (organization_id && organization_id !== 'undefined') {
    return APIS.getRoles({ organization_id })
      .then((res) => {
        dispatch(fetchRolesSuccess(res.result.data))
      })
      .catch((error) => {
        dispatch(fetchRolesFailure(error))
      })
  }
}
