import React, { Fragment } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
} from '@mui/material'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'

import theme from '../../../assets/scss/variables.scss'
import {
  useProjectFormUtilities,
  useProjectUtilities,
} from '../../../hooks/useProjectUtilities'
import { ProjectBodyLayout } from '../Layout'
import { APP_CONSTANTS, PROJECTS } from '../../../constants'
import { APIS } from '../../../services'
import { notify } from '../../../utils'
import { projectForm as projectForm_ } from '../../../redux/projects/selectors'

const CustomTextField = styled((props) => (
  <TextField {...props} size="small" />
))(() => ({
  '--TextField-brandBorderColor': theme.black,
  '--TextField-brandBorderHoverColor': theme.black,
  '--TextField-brandBorderFocusedColor': theme.black,
  '& label.Mui-focused': {
    color: theme.black,
  },
}))

const CustomSelect = styled((props) => <Select {...props} size="small" />)(
  () => ({
    borderRadius: 4,
    '&.Mui-focused': {
      borderColor: 'none',
      boxShadow: 'none',
    },
  })
)

const Configurations = () => {
  const { setLoading, increaseStep, setConfigForm } = useProjectFormUtilities()
  const { fetchProjects } = useProjectUtilities()
  const {
    currentOrganization: { id: organization_id },
  } = useSelector((state) => state.organization)
  const { projectType, name, methodology, unitSystem } =
    useSelector(projectForm_)

  const configForm = useFormik({
    initialValues: {
      name: name ?? '',
      methodology: methodology ?? '',
      unitSystem: unitSystem ?? '',
    },
    validate: (values) => {
      const errors = {}

      // using formik we can validate the fields, in this case i added validations to the form
      // and know if the values are valid in the form (existing values)
      // we can also use libraries to validate the fields, for example: yup, valibot or zod

      if (!values.name) errors.name = '* Field required to create a workspace'

      if (!values.methodology)
        errors.methodology = '* Field required to create a workspace'

      if (!values.unitSystem)
        errors.unitSystem = '* Field required to create a workspace'

      return errors
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true)

        const params = {
          name: values.name,
          // this attribute is not used anymore in the form
          project_description: '',
          organization_id,
          unit_system: values.unitSystem,
          methodology: values.methodology,
          type: projectType,
          field_ids: [],
        }

        await APIS.postProject(params)
        await fetchProjects({ organization_id })

        // the data form is saved in redux store in case of change or future use
        setConfigForm(values)

        // the form is reset after save all the data
        resetForm()
        increaseStep()
        notify('Workspace created', 'success')
      } catch (err) {
        console.log({ err })
        setLoading(false)
        notify('Unable to create Workspace', 'error')
      }
    },
  })

  return (
    <ProjectBodyLayout
      title="Configure Project"
      sub="Customize your “workspace” to your project type.  This allows us to run the appropriate models, present information in your desired units, and provide guidelines and dates for various parts of the project process."
    >
      <Box className="project-modal-step-content">
        <form
          className="project-modal-step-form"
          id="create-project-form"
          onSubmit={configForm.handleSubmit}
        >
          <Box className="project-modal-step-form-row">
            <FormControl fullWidth>
              <CustomTextField
                id="project-configuration-name"
                label="Workspace Name"
                name="name"
                value={configForm.values.name}
                onChange={configForm.handleChange}
                onBlur={configForm.handleBlur}
                error={Boolean(configForm.errors.name)}
                helperText={configForm.errors.name}
              />
            </FormControl>
          </Box>

          <Box className="project-modal-step-form-row">
            <FormControl sx={{ width: '60%' }}>
              <InputLabel
                id="methodology-select-label"
                size="small"
                color="dark"
              >
                Methodology
              </InputLabel>
              <CustomSelect
                labelId="methodology-select-label"
                id="demo-simple-select"
                label="Methodology"
                size="small"
                name="methodology"
                value={configForm.values.methodology}
                onChange={configForm.handleChange}
                onBlur={configForm.handleBlur}
                error={Boolean(configForm.errors.methodology)}
              >
                {PROJECTS.FORM.METHODOLOGY.map(({ label, value }) => (
                  <MenuItem value={value} key={`project-methodology-${value}`}>
                    {label}
                  </MenuItem>
                ))}
              </CustomSelect>
              {Boolean(configForm.errors.methodology) && (
                <FormHelperText>{configForm.errors.methodology}</FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ width: '35%' }}>
              <InputLabel id="unit-select-label" size="small" color="dark">
                Unit System
              </InputLabel>
              <CustomSelect
                labelId="unit-select-label"
                id="demo-simple-select"
                label="Unit System"
                size="small"
                name="unitSystem"
                value={configForm.values.unitSystem}
                onChange={configForm.handleChange}
                onBlur={configForm.handleBlur}
                error={Boolean(configForm.errors.unitSystem)}
              >
                {APP_CONSTANTS.UNIT_SYSTEM.map(({ label, value }) => (
                  <MenuItem value={value} key={`project-unit-system-${value}`}>
                    {label}
                  </MenuItem>
                ))}
              </CustomSelect>
              {Boolean(configForm.errors.unitSystem) && (
                <FormHelperText>{configForm.errors.unitSystem}</FormHelperText>
              )}
            </FormControl>
          </Box>
        </form>
      </Box>
    </ProjectBodyLayout>
  )
}

const Footer = ({ onExit }) => {
  const { loading } = useSelector(projectForm_)

  return (
    <Fragment>
      <Button className="project-modal-step-exit-button" onClick={onExit}>
        Exit
      </Button>
      <Button
        className="project-modal-step-button"
        type="submit"
        form="create-project-form"
        disabled={loading}
      >
        Next
      </Button>
    </Fragment>
  )
}

Configurations.Footer = Footer

export default Configurations
