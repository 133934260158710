import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import AllLanguages from './i18ntranslation/all_languages.json'
import { Sider } from './components'
import Routes from './routes'
import { useSelector } from 'react-redux'
import theme from '../src/assets/scss/variables.scss'
import { Grid } from '@mui/material'
import Header from './components/Header'
import * as amplitude from '@amplitude/analytics-browser'
import SentryRouteListener from './routes/SentryRouteListener'

const Layout = () => {
  const { locale } = useSelector((state) => state.localization)
  const { currentUser } = useSelector((state) => state.user)
  const { currentOrganization } = useSelector((state) => state.organization)

  useEffect(() => {
    const identifyEvent = new amplitude.Identify()
    identifyEvent.set('organization', currentOrganization.name)
    amplitude.identify(identifyEvent)
    currentUser &&
      amplitude.init(
        process.env.REACT_APP_AMPLITUDE_API_KEY,
        currentUser.primary_email,
        {
          defaultTracking: {
            attribution: false,
            pageViews: true,
            sessions: false,
            formInteractions: false,
            fileDownloads: false,
          },
        }
      )
  }, [currentUser, currentOrganization])

  return (
    <IntlProvider
      locale={locale}
      defaultLocale="en"
      messages={AllLanguages[locale]}
    >
      <BrowserRouter>
        <SentryRouteListener />
        <Grid
          container
          sx={{
            width: '100%',
            minHeight: '100vh',
            backgroundColor: theme.blackColor,
            display: 'flex',
          }}
          columns={64}
        >
          <Grid item xs={4} xl={3}>
            <Sider />
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
            xs={60}
            xl={61}
          >
            <Grid item>
              <Header />
            </Grid>
            <Grid sx={{ flexGrow: 1, display: 'flex' }} item>
              <Routes />
            </Grid>
          </Grid>
        </Grid>
      </BrowserRouter>
    </IntlProvider>
  )
}

export default Layout
