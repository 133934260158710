// src/mocks/browser.js
import { setupWorker } from 'msw/browser'

/**
 * Configure API mocks using Mock Service Worker
 *
 * See https://mswjs.io/docs/integrations/browser
 *
 * API mocks are only enabled if the REACT_APP_MOCK_API_SERVER env var is set
 *
 * See client/README.md for instructions on setting up mocks.
 */
export const start = async () => {
  if (
    !process.env.REACT_APP_MOCK_API_SERVER ||
    !['on', 'yes', 'true', '1'].includes(process.env.REACT_APP_MOCK_API_SERVER)
  ) {
    return
  }

  let handlers
  try {
    // Catch and warn if the handlers.js file is missing
    // The handlers.js file is gitignored, and will not exist by default
    // Mock API handlers are meant to be short-term local development aids.
    let importPath = './handlers'
    // passing in the handler path as a variable avoids build errors, when handlers.js is missing in CI
    handlers = (await import(`${importPath}`)).default
  } catch (err) {
    console.warn('Failed to import MSW handlers', err)
    return
  }

  const worker = setupWorker(...handlers)

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}
