import React, { Fragment } from 'react'
import { Box, Button } from '@mui/material'

import { ProjectBodyLayout } from '../Layout'
import LandingImage from '../../../assets/img/landing_image.png'

const Demo = () => {
  return (
    <ProjectBodyLayout
      title="Demo"
      sub="Video demo of what is possible in projects. As well as a link to documentation."
    >
      <Box className="project-modal-step-landing">
        <img
          src={LandingImage}
          alt="landing-image"
          className="project-modal-image"
          style={{ width: '100%' }}
        />
      </Box>
    </ProjectBodyLayout>
  )
}

const Footer = ({ onExit }) => {
  return (
    <Fragment>
      <Button className="project-modal-step-exit-button" onClick={onExit}>
        Exit
      </Button>
      <Button className="project-modal-step-button" onClick={onExit}>
        Complete
      </Button>
    </Fragment>
  )
}

Demo.Footer = Footer

export default Demo
