import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { completeLogin, logout } from '../../redux/user/actions'
import { APIS } from '../../services'
import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { Hub, Auth } from 'aws-amplify'
import { ReactComponent as PerennialLogo } from '../../assets/img/Colour-Perennial-rgb-green-horiz.svg'
import './auth.scss'

const AuthPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleLogin = (username) => {
    dispatch(completeLogin(username))
    APIS.cognitoCallback()
      .then(() => {
        const query = new URLSearchParams(location.search).get('redirect_uri')
        history.push(query || '/field-prospecting')
      })
      .catch(() =>
        dispatch(
          logout(
            history,
            'No account for this email - Please contact your administrator'
          )
        )
      )
  }

  useEffect(() => {
    // listen for successful signin
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'signIn') {
        handleLogin(data.username)
      }
    })

    // if the user is already logged in (or coming from federated), we need to process their login
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          handleLogin(user.username)
        }
      })
      .catch(() => {})

    // unsubscribe from listener when we unmount component
    return unsubscribe
  }, [])

  const components = {
    Header() {
      return (
        <div className="login-logo">
          <PerennialLogo />
        </div>
      )
    },
  }

  return (
    <div className="login-container">
      <div className="login">
        <Authenticator
          components={components}
          loginMechanisms={['email']}
          hideSignUp
        />
      </div>
    </div>
  )
}
export default AuthPage
