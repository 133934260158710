import { omit } from 'lodash'
export const SET_FIELD_ATTR = 'ranches/SET_FIELD_ATTR'
export const RESULTS = 'ranches/RESULTS'
export const UPDATE_RANCH = 'ranches/UPDATE_RANCH'
export const BULK_RANCHES = 'ranches/BULK_RANCHES'
export const UPDATE_FIELD_RANCH = 'ranches/UPDATE_FIELD_RANCH'
export const REMOVE_FIELD_RANCH = 'ranches/REMOVE_FIELD_RANCH'

const initialState = {
  // request attributes
  inFlight: false,
  requestId: null,

  // request list
  ranches: [],
  ranchSelected: {},
}

const updateRanch = (state, action) => {
  const indexFound = state.ranches.findIndex(
    ({ ag_field_uuid }) => ag_field_uuid === action?.ag_field_uuid
  )

  if (indexFound === -1) return state

  const ranches = [...state.ranches]

  ranches[indexFound] = {
    ...state.ranches[indexFound],
    ...(action.values || {}),
  }

  return { ...state, ranches }
}

const bulkRanches = (state, action) => {
  const ranches = state.ranches?.map((field) => {
    if (action?.uuids?.includes(field?.ag_field_uuid)) {
      return { ...field, ...(action.values || {}) }
    }
    return field
  })

  return { ...state, ranches }
}

const updateFieldRanch = (state, action) => {
  const allCurrentRanches = [...state.ranches]
  const ranches = allCurrentRanches?.map((ranch) => {
    const ag_fields = ranch.ag_fields?.map((field) => {
      if (
        field?.ag_field_uuid === action.uuids ||
        action?.uuids?.includes(field?.ag_field_uuid)
      ) {
        return { ...field, ...(action.values || {}) }
      }
      return field
    })

    return { ...ranch, ag_fields }
  })

  return { ...state, ranches }
}

const removeFieldFromRanch = (state, action) => {
  const allCurrentRanches = [...state.ranches]
  const ranches = allCurrentRanches?.map((ranch) => {
    const fields = ranch.ag_fields?.filter(
      (field) =>
        !(
          field?.ag_field_uuid === action.uuids ||
          action?.uuids?.includes(field?.ag_field_uuid)
        )
    )
    return { ...ranch, ag_fields: fields }
  })

  return { ...state, ranches }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FIELD_ATTR:
      return {
        ...state,
        ...omit(action, 'type'),
      }
    case RESULTS:
      // the request id check is for slow debounces (taking the same reference from dashboard)
      return state.requestId === action.requestId
        ? {
            ...state,
            ranches: action.ranches,
            inFlight: false,
            requestId: initialState.requestId,
          }
        : state
    case UPDATE_RANCH:
      return updateRanch(state, action)
    case BULK_RANCHES:
      return bulkRanches(state, action)
    case UPDATE_FIELD_RANCH:
      return updateFieldRanch(state, action)
    case REMOVE_FIELD_RANCH:
      return removeFieldFromRanch(state, action)
    default:
      return state
  }
}

export default reducer
