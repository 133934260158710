import { get } from 'lodash'
import { createSelector } from 'reselect'

export const loggedIn = (state) =>
  get(state, 'user.identity') && get(state, 'user.currentUser.id')

export const isAdmin = (state) =>
  get(state, 'user.currentUser.user_role') === 'admin'

export const currentOrganizationId = (state) =>
  get(state, 'organization.currentOrganization.id')

export const unitSystem = (state) =>
  get(state, 'organization.currentOrganization.unit_system')

export const areaUnits = createSelector(unitSystem, (unitSystem) =>
  unitSystem == 'imperial' ? 'acres' : 'hectares'
)

export const weightUnits = createSelector(unitSystem, (unitSystem) =>
  unitSystem == 'imperial' ? 'tons' : 'tonnes'
)

export const potentialUnits = createSelector(unitSystem, (unitSystem) =>
  unitSystem == 'imperial' ? 't/ac/yr' : 't/ha/yr'
)

export const cognitoUserId = (state) => get(state, 'user.identity')
