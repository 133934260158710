import { omit } from 'lodash'
export const SET_FIELD_ATTR = 'masks/SET_FIELD_ATTR'
export const RESULTS = 'masks/RESULTS'
export const RESET = 'masks/RESET'

const initialState = {
  // request attributes
  inFlight: false,
  requestId: null,

  // masks without pagination
  // page: 1,
  // limit: 80,
  // availableData: true,

  // request list
  masks: [],
  appliedMask: {},

  // specific mask selected for fields
  maskSelected: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FIELD_ATTR:
      return {
        ...state,
        ...omit(action, 'type'),
      }
    case RESULTS:
      // the request id check is for slow debounces (taking the same reference from dashboard)
      return state.requestId === action.requestId
        ? {
            ...state,
            masks: action.masks,
            inFlight: false,
            requestId: initialState.requestId,
            appliedMask: action.appliedMask,
          }
        : state
    case RESET:
      return { ...initialState }
    default:
      return state
  }
}

export default reducer
