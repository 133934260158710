import React from 'react'
import { Layout } from 'antd'
const { Header, Content } = Layout
import theme from '../../assets/scss/variables.scss'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.debug(error, errorInfo)
    // TODO: Log to sentry
  }

  render() {
    if (this.state.hasError) {
      return (
        <Layout className="layout">
          <Header style={{ height: '10vh', background: theme.blackColor }}>
            <div className="logo" />
          </Header>
          <Content>
            <div style={{ padding: '5vw', height: '90vh' }}>
              <h2>{`We're sorry - the platform has encountered an error`}</h2>
              <p>{`There was an internal issue with our platform that has caused an error. Don't worry, we've been notified and will work diligently to fix the problem. If you need immediate assistance, please contact the Perennial team.`}</p>
            </div>
          </Content>
        </Layout>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
