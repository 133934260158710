import React from 'react'
import { notification } from 'antd'
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'

export const notify = (description, type, duration = 5) => {
  let messageType = 'Information'
  let icon = <InfoCircleOutlined style={{ color: 'blue' }} />

  switch (type) {
    case 'success':
      messageType = 'Success'
      icon = <CheckCircleOutlined style={{ color: 'green' }} />
      break
    case 'error':
      messageType = 'Failure'
      icon = <CloseCircleOutlined style={{ color: 'red' }} />
      break
    default:
      messageType = 'Information'
  }
  notification.open({
    message: messageType,
    description,
    icon,
    duration,
  })
}
