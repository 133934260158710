export const SET_LOCALE = 'SET_LOCALE'
import AllLanguages from '../../i18ntranslation/all_languages.json'

const initialState = {
  locale: 'en',
  availableLanguages: Object.keys(AllLanguages),
  currentLanguage: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCALE:
      return {
        ...state,
        locale: action.payload,
      }
    default:
      return state
  }
}

export default reducer
