import { outlinedInputClasses } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import themeVariables from '../assets/scss/variables.scss'
import { amber, blue, cyan, green, red } from '@mui/material/colors'

const {
  project0,
  project1,
  project2,
  project3,
  project4,
  linksColor,
  black,
  cancelModalButton,
} = themeVariables

const whiteColor = '#fff'
const blackColor = '#031718'
const black10 = '#101010'
const tealColor = '#255751'
const greyColor = '#9aabab'
const perennialRed = '#EB5757'
const orangeColor = '#ffa500'
const lightMint = '#D7E5E5'
const oceanColor = '#609699'
const greyColor10 = '#f7f8f8'
const greyColor25 = '#e6eaea'
const greyColor40 = '#d6dddd'
const lightGreyColor = '#f2f2f2'
const grey82 = '#828282'
const darkGrey3 = '#d9d9d933'
const secondaryColor = '#8B9A9A'
const black15 = '#313131'
const black25 = '#2c2c2c'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: whiteColor,
    },
    secondary: {
      main: tealColor,
    },
    background: {
      default: blackColor,
      paper: whiteColor,
    },
    warning: {
      main: orangeColor,
    },
    error: {
      main: perennialRed,
    },
    dark: {
      main: black,
    },
    text: {
      primary: blackColor,
      secondary: whiteColor,
      disabled: greyColor,
    },
    project0: {
      main: project0,
    },
    project1: {
      main: project1,
    },
    project2: {
      main: project2,
    },
    project3: {
      main: project3,
    },
    project4: {
      main: project4,
    },
    initial: {
      light: blue[100],
      main: blue[400],
      dark: blue[900],
      contrastText: black10,
    },
    sampling: {
      light: cyan[100],
      main: cyan[400],
      dark: cyan[900],
      contrastText: black10,
    },
    lab_results: {
      light: amber[100],
      main: amber[400],
      dark: amber[900],
      contrastText: black10,
    },
    validated: {
      light: green[100],
      main: green[400],
      dark: green[900],
      contrastText: black10,
    },
    cancelled: {
      light: red[100],
      main: red[400],
      dark: red[900],
      contrastText: black10,
    },
    links: {
      primary: linksColor,
      secondary: lightMint,
      disabled: oceanColor,
    },
    misc: {
      black25: black25,
      grey82: grey82,
      greyColor40: greyColor40,
      lightGreyColor: lightGreyColor,
      black15: black15,
      cancelModalButton: cancelModalButton,
    },
  },
  typography: {
    fontFamily: [
      'Degular-Regular',
      'Degular-Semibold',
      'Tahoma',
      'sans-serif',
    ].join(','),
  },
  components: {
    // Name of the component
    MuiToggleButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          color: blackColor,
          borderRadius: '2px',
          height: '32px',
          padding: '0 15px',
          background: whiteColor,
          border: `1px solid ${greyColor40}`,
          textTransform: 'none',
          ':hover': { background: 'transparent' },
          '&.Mui-selected': {
            background: lightMint,
            border: `1px solid ${oceanColor}`,
            '&:hover': { background: lightMint },
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          background: whiteColor,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: '15px',
          boxShadow: 'none',
          minWidth: '100px',
          textTransform: 'none',
          fontWeight: 'bolder',
          '& .MuiTypography-root': {
            fontSize: 'inherit',
            fontFamily: 'Degular-Semibold',
          },
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: oceanColor,
              border: whiteColor,
              color: whiteColor,
              ':hover': { backgroundColor: greyColor40, boxShadow: 'none' },
              '&.Mui-disabled': {
                backgroundColor: greyColor10,
                color: whiteColor,
                pointerEvents: 'all',
                cursor: 'not-allowed',
              },
            }),
        }),
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
          background: whiteColor,
          borderRadius: '2px',
          width: '100%',
          height: '100%',
          padding: '4px 11px',
          border: `1px solid ${greyColor40}`,
          '& .MuiInputBase-input::placeholder': { color: secondaryColor },
          ':hover': { border: `1px solid ${oceanColor}` },
          '&.Mui-focused': {
            borderColor: oceanColor,
            boxShadow: `0 0 0 2px rgba(96, 150, 153, 0.2)`,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { color: perennialRed },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: { color: blackColor, marginRight: '2px' },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: greyColor40,
          ':hover': { color: oceanColor },
          '&.Mui-checked': {
            color: oceanColor,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 'inherit',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
          borderRadius: '15px',
          backgroundColor: whiteColor,
          ':hover': { borderColor: oceanColor },
          '&.Mui-focused': {
            borderColor: oceanColor,
            boxShadow: '0 0 0 2px rgba(96, 150, 153, 0.2)',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '4px 8px',
          '&:last-child': {
            paddingBottom: '8px',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: greyColor25,
          marginBottom: '8px',
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          '& .MuiStepLabel-root': {
            '& .MuiStepLabel-label': {
              fontSize: '16px',
              color: greyColor,
              paddingLeft: '12px',
            },
            '& .MuiSvgIcon-root': {
              color: oceanColor,
              fontSize: '30px',
              '& .MuiStepIcon-text': {
                fill: whiteColor,
              },
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': greyColor40,
          '--TextField-brandBorderHoverColor': greyColor,
          '--TextField-brandBorderFocusedColor': greyColor,
          '& label.Mui-focused': {
            color: 'var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'var(--TextField-brandBorderColor)',
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'var(--TextField-brandBorderHoverColor)',
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: blackColor,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: black10,
          padding: '0 10px',
        },
        root: {
          '& .MuiListItem-root': {
            marginBottom: '20px',
            padding: '0 10px',
            display: 'block',
          },
          '& .MuiInputBase-root': {
            height: '36px',
            backgroundColor: black10,
            border: `1px solid ${grey82}`,
            borderRadius: '5px',
            color: whiteColor,
            '& .MuiSvgIcon-root': { color: whiteColor },
            '&.Mui-focused': {
              border: 'none',
              borderColor: 'transparent',
              boxShadow: 'none',
            },
          },
          '& .MuiDivider-root': {
            borderColor: grey82,
            margin: '30px 0',
          },
          '& .MuiListItemButton-root': {
            border: `1px solid transparent`,
            borderRadius: '5px',
            color: grey82,
            padding: '0 8px',
            marginBottom: '20px',
            '& .MuiSvgIcon-root': {
              fill: grey82,
            },
            '&:hover': {
              border: `1px solid transparent`,
              borderRadius: '5px',
              backgroundColor: darkGrey3,
              color: whiteColor,
              '.MuiSvgIcon-root': {
                fill: whiteColor,
              },
            },
            '&.Mui-selected': {
              backgroundColor: darkGrey3,
              color: whiteColor,
              border: `1px solid ${grey82}`,
              borderRadius: '5px',
              '.MuiSvgIcon-root': {
                fill: whiteColor,
              },
            },
          },
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: blackColor,
        },
      },
    },
    MuiDataGrid: {
      row: { height: 42 }, //this is the height of the rows on TableSkeleton component
      styleOverrides: {
        root: {
          '& .MuiDataGrid-toolbarContainer': {
            '& .MuiButton-root': {
              color: blackColor,
            },
          },
        },
      },
    },
  },
})
export default theme
