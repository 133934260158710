import { omit } from 'lodash'

import {
  parsePaginationFieldData,
  parsePaginationSummaryData,
} from '../../utils/fields'

export const SET_FIELD_ATTR = 'project/SET_FIELD_ATTR'
export const RESULTS = 'fields/RESULTS'
export const RESULT_FOCUSED_FIELDS = 'fields/RESULT_FOCUSED_FIELDS'
export const RESULTS_PAGINATED = 'fields/RESULTS_PAGINATED'
export const RESET_PAGINATION = 'fields/RESET_PAGINATION'
export const RESET = 'fields/RESET'
export const INCREASE_PAGINATION = 'fields/INCREASE_PAGINATION'
export const RESULTS_OF_RANCHES = 'fields/RESULTS_OF_RANCHES'
export const UPDATE_FIELD = 'fields/UPDATE_FIELD'
export const BULK_FIELDS = 'fields/BULK_FIELDS'
export const EDIT_FILTER = 'fields/EDIT_FILTER'
export const SET_SORT = 'fields/SET_SORT'

const initialState = {
  // request attributes fields
  inFlight: false,
  requestId: null,
  page: 1,
  limit: 50,
  availableData: true,
  sortBy: '',
  sortOrder: null,

  // filters

  filters: {
    rating: [],
    area: [],
    tags: [],
    farm: '',
    land_use: '',
    county: '',
    state: '',
  },

  // request focused fields
  inFlightFocusedFields: false,
  focusedFieldsRequestId: null,
  bbox: [],

  // request list
  fields: [],
  focusedFields: [],

  // specific field selected for fields
  fieldSelected: {},
  fieldsInBoundary: [],

  // summary
  summary: {
    area_in_hectares: 0,
    fields_count: 0,
    soc_sequestration_potential_in_metric_tons_CO2e: 0,
    soc_stock_in_metric_tons: 0,
  },
  inFlightSummary: false,

  previewedFieldId: null,
}

export const updateField = (state, action) => {
  const indexFound = state.fields.findIndex(
    ({ ag_field_uuid }) => ag_field_uuid === action?.ag_field_uuid
  )

  if (indexFound === -1) return state

  const fields = [...state.fields]

  fields[indexFound] = {
    ...state.fields[indexFound],
    ...(action.values || {}),
  }

  return { ...state, fields }
}

export const bulkFields = (state, action) => {
  const fields = state.fields?.map((field) => {
    if (action?.uuids?.includes(field?.ag_field_uuid)) {
      return { ...field, ...(action.values || {}) }
    }
    return field
  })

  return { ...state, fields }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FIELD_ATTR:
      return {
        ...state,
        ...omit(action, 'type'),
      }
    case RESULTS:
      // the request id check is for slow debounces (taking the same reference from dashboard)
      return state.requestId === action.requestId
        ? {
            ...state,
            fields: action.fields,
            inFlight: false,
            requestId: initialState.requestId,
            inFlightSummary: false,
            summary: action.summary || { ...initialState.summary },
          }
        : state
    case RESULTS_PAGINATED:
      // the request id check is for slow debounces (taking the same reference from dashboard)
      return state.requestId === action.requestId
        ? {
            ...state,
            fields: parsePaginationFieldData(
              action.fields,
              state.fields,
              action.page
            ),
            page: action.page,
            limit: action.limit,
            availableData: action.availableData,
            inFlight: false,
            requestId: initialState.requestId,
            inFlightSummary: false,
            summary: action.summary
              ? parsePaginationSummaryData(
                  action.summary,
                  state.summary,
                  action.page
                )
              : { ...initialState.summary },
          }
        : state
    case RESULT_FOCUSED_FIELDS:
      // the request id check is for slow debounces (taking the same reference from dashboard)
      return state.focusedFieldsRequestId === action.focusedFieldsRequestId
        ? {
            ...state,
            focusedFields: action.focusedFields,
            bbox: action.bbox,
            inFlightFocusedFields: false,
            focusedFieldsRequestId: initialState.requestId,
          }
        : state
    case RESET_PAGINATION:
      return {
        ...state,
        page: initialState.page,
        limit: initialState.limit,
        availableData: initialState.availableData,
      }
    case INCREASE_PAGINATION:
      return {
        ...state,
        page: state.page + 1,
      }
    case UPDATE_FIELD:
      return updateField(state, action)
    case BULK_FIELDS:
      return bulkFields(state, action)
    case RESET:
      return { ...initialState }
    case EDIT_FILTER:
      return {
        ...state,
        filters: action.filters,
        page: initialState.page,
        limit: initialState.limit,
        availableData: initialState.availableData,
      }
    case SET_SORT:
      return {
        ...state,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder,
      }
    default:
      return state
  }
}

export default reducer
