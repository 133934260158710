import { omit } from 'lodash'
export const SET_STRATIFICATION_PARAMETERS_ATTR =
  'stratificationParameters/SET_PROJECT_ATTR'
export const STRATIFICATION_PARAMETERS_RESET =
  'stratificationParameters/PROJECT_RESET'
export const STRATIFICATION_PARAMETERS_SET_COUNT =
  'stratificationParameters/SET_COUNT'
export const STRATIFICATION_PARAMETERS_SET_DEPTHS =
  'stratificationParameters/SET_DEPTHS'
export const STRATIFICATION_PARAMETERS_SET_PROBE_TYPE =
  'stratificationParameters/SET_PROBE_TYPE'
export const STRATIFICATION_PARAMETERS_INCREASE_TYPES =
  'stratificationParameters/INCREASE_TYPES'
export const STRATIFICATION_PARAMETERS_REMOVE_TYPES =
  'stratificationParameters/REMOVE_TYPES'

const initialState = {
  // fields
  inFlight: false,
  selectedFields: [],
  saved: false,

  // form
  method: null,
  layers: null,
  // a list of dynamic types { count: number, depths: string }
  sampleTypes: [{ count: 0, depths: null, probe_type: null }],

  // behavior
  lastFieldSelected: {},
  selectedField: {},
  stratumBoundaries: {},

  // pendign status
  stratificationStatusId: null,
}

const parseSampleTypes = (state, action) => {
  const actionType =
    STRATIFICATION_PARAMETERS_SET_COUNT === action.type ? 'count' : 'depths'
  const currentSampleTypes = [...state.sampleTypes]
  const currentSample = { ...currentSampleTypes[action.index] }
  currentSample[actionType] = action[actionType]
  currentSampleTypes[action.index] = currentSample
  return { ...state, sampleTypes: currentSampleTypes }
}

const parseSampleProbeType = (state, action) => {
  const currentSampleTypes = [...state.sampleTypes]
  const currentSample = { ...currentSampleTypes[action.index] }
  currentSample.probe_type = action.probe_type
  currentSampleTypes[action.index] = currentSample
  return { ...state, sampleTypes: currentSampleTypes }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STRATIFICATION_PARAMETERS_ATTR:
      return {
        ...state,
        ...omit(action, 'type'),
      }
    case STRATIFICATION_PARAMETERS_RESET:
      return { ...initialState }
    case STRATIFICATION_PARAMETERS_SET_COUNT:
      return parseSampleTypes(state, action)
    case STRATIFICATION_PARAMETERS_SET_DEPTHS:
      return parseSampleTypes(state, action)
    case STRATIFICATION_PARAMETERS_SET_PROBE_TYPE:
      return parseSampleProbeType(state, action)
    case STRATIFICATION_PARAMETERS_INCREASE_TYPES:
      return {
        ...state,
        sampleTypes: [
          ...state.sampleTypes,
          { count: null, depths: null, probe_type: null },
        ],
      }
    case STRATIFICATION_PARAMETERS_REMOVE_TYPES:
      return {
        ...state,
        sampleTypes: state.sampleTypes.filter(
          (_, index) => index !== action.payload
        ),
      }
    default:
      return state
  }
}

export default reducer
