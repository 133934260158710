import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button } from '@mui/material'

import { useProjectFormUtilities } from '../../../hooks/useProjectUtilities'
import { ProjectBodyLayout } from '../Layout'
import { SimpleRadioGroup } from '../../../components/SimpleRadioGroup'
import { projectFormType as projectFormType_ } from '../../../redux/projects/selectors'
import { PROJECTS } from '../../../constants'

const Types = () => {
  const projectFormType = useSelector(projectFormType_)
  const { setFormType } = useProjectFormUtilities()

  const handleChange = (event) => {
    setFormType({ projectType: event.target.value })
  }

  return (
    <ProjectBodyLayout
      title="Choose your Workspace Type"
      sub="This allows us to customize your “workspace” to your project type.  For example, if you select an Offset project, we will provide you with templates specific to the preparation of a Soil Carbon Offset"
    >
      <Box className="project-modal-step-content">
        <SimpleRadioGroup
          radios={PROJECTS.FORM.PROJECT_TYPES}
          value={projectFormType}
          onChange={handleChange}
        />
      </Box>
    </ProjectBodyLayout>
  )
}

const Footer = ({ onExit }) => {
  const { increaseStep } = useProjectFormUtilities()
  const projectFormType = useSelector(projectFormType_)

  return (
    <Fragment>
      <Button className="project-modal-step-exit-button" onClick={onExit}>
        Exit
      </Button>
      <Button
        className="project-modal-step-button"
        onClick={increaseStep}
        disabled={!projectFormType}
      >
        Next
      </Button>
    </Fragment>
  )
}

Types.Footer = Footer

export default Types
