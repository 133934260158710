export const SET_USER_INIT_DATA = 'user/SET_USER_INIT_DATA'
export const SET_IDENTITY = 'user/SET_IDENTITY'

const initialState = {
  currentUser: {}, // current user stores the mongo user object
  identity: null, // identity stores the user_pool_id (a uuid) that represents the user in cognito
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IDENTITY:
      return {
        ...state,
        identity: action.identity,
      }
    case SET_USER_INIT_DATA:
      return {
        ...state,
        currentUser: action.payload.user,
      }
    default:
      return state
  }
}

export default reducer
