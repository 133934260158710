/**
 * Converts CO2 tons per hectare to tons per acre.
 *
 * @param {number|null} value - The value in CO2 tons per hectare.
 * @return {number|null} The converted value in tons per acre, or null if the input is null.
 */
const HECTARES_TO_ACRES = 2.47105

const convertCO2ETonsPerHectareToPerAcre = (value) => {
  if (value == null) return null

  return Number(value / HECTARES_TO_ACRES)
}
export default convertCO2ETonsPerHectareToPerAcre
