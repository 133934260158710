import { convertCarbonToCO2equivalent } from './socTier'
import convertCO2ETonsPerHectareToPerAcre from './convertCO2ETonsPerHectareToPerAcre'

/**
 * This function takes a CO2 sequestration rating whose value is in
 *   metric tons per hectare per year, converts it to CO2e, and also conditionally converts
 *   it to a different unit system.
 * By default, the rating value is returned in metric tonnes per hectare per year.
 *
 * @param {number} rating The CO2 sequestrating rating of a field in metric tonnes yer hectare per year
 * @param {string='metric'} unitsToDisplay The desired unit system for the converted rating value
 * @returns {number} Ths CO2e sequestration rating in tonnes per display unit system area per year
 */
const getSequestrationRatingForDisplay = (
  rating,
  unitsToDisplay = 'metric'
) => {
  if (rating == null) {
    return null
  }

  let convertedRating = convertCarbonToCO2equivalent(rating)
  if (unitsToDisplay === 'imperial') {
    convertedRating = convertCO2ETonsPerHectareToPerAcre(convertedRating)
  }
  return convertedRating.toFixed(2)
}

export default getSequestrationRatingForDisplay
