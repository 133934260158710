import { omit } from 'lodash'

export const SET_APP_ATTR = 'app/SET_APP_ATTR'
export const SET_CONTAINER_WIDTH = 'app/SET_CONTAINER_WIDTH'

const initialState = {
  selectedItem: null,
  containerWidth: 0,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_ATTR:
      return {
        ...state,
        ...omit(action, 'type'),
      }
    case SET_CONTAINER_WIDTH:
      return {
        ...state,
        containerWidth: action.width,
      }
    default:
      return state
  }
}

export default reducer
