import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { sortBy as _sortBy } from 'lodash-es'
import { setCurrentOrganization } from '../../redux/organization/actions'
import theme from '../../assets/scss/variables.scss'
import { MenuItem, FormControl, Select as MuiSelect } from '@mui/material'

const OrganizationList = ({ hide }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const { currentOrganization, myOrganizations } = useSelector(
    (state) => state.organization
  )
  const history = useHistory()

  // ensure orgs is an array with at least 1 org
  const myOrgs =
    myOrganizations.length > 0 ? myOrganizations : [currentOrganization]
  // filter out inactive orgs
  const activeOrgs = myOrgs.filter((org) => org.is_active)
  // sort orgs by name
  const sortedOrgs = _sortBy(activeOrgs, [(org) => org.name.toLowerCase()])

  const onChange = (event) => {
    const orgList = myOrganizations.filter(
      (org) => org.id === event.target.value
    )
    history.push('/field-prospecting')
    dispatch(setCurrentOrganization(orgList[0]))
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  useEffect(() => {
    hide && setOpen(false)
  }, [hide])

  return (
    <FormControl fullWidth>
      <MuiSelect
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        disabled={hide}
        value={currentOrganization?.id}
        onChange={onChange}
        MenuProps={{
          PaperProps: {
            sx: { backgroundColor: theme.black10, color: theme.whiteColor },
          },
        }}
      >
        <MenuItem disabled value="">
          <em>None</em>
        </MenuItem>
        {sortedOrgs &&
          sortedOrgs.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
      </MuiSelect>
    </FormControl>
  )
}

export default OrganizationList
