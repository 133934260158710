import React from 'react'
import { Backdrop, Typography, CircularProgress } from '@mui/material'
import theme from '../../assets/scss/variables.scss'

const LoadingBackdrop = ({ loading, text }) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        backgroundColor: `rgba(${theme.blackColor}, 0.3)`,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: 'flex',
      }}
      open={loading}
      transitionDuration={500}
    >
      <CircularProgress color="inherit" />
      <Typography ml={2} fontSize={25}>
        {text ? text : 'Loading...'}
      </Typography>
    </Backdrop>
  )
}

export default LoadingBackdrop
