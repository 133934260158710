import { SET_CURRENT_ORGANIZATION } from '../organization'
import { omit } from 'lodash'
export const SET_PROJECT_ATTR = 'project/SET_PROJECT_ATTR'
export const RESULTS = 'project/RESULTS'
export const PROJECT_RESULT = 'project/PROJECT_RESULT'
export const PROJECT_MASKS_RESULT = 'project/PROJECT_MASKS_RESULT'
export const PROJECT_RESET = 'project/PROJECT_RESET'
export const PROJECT_SET_BOUNDARY = 'project/PROJECT_SET_BOUNDARY'
export const PROJECT_REMOVE_EDITED_BOUNDARY =
  'project/PROJECT_REMOVE_EDITED_BOUNDARY'
export const PROJECT_SET_MASK_BOUNDARY = 'project/PROJECT_SET_MASK_BOUNDARY'
export const PROJECT_PUSH_NEW_MASK = 'project/PROJECT_PUSH_NEW_MASK'
export const PROJECT_SET_NEW_MASK_SHAPE = 'project/PROJECT_SET_NEW_MASK_SHAPE'
export const PROJECT_SET_NEW_FIELDS = 'project/PROJECT_SET_NEW_FIELDS'
export const SET_PROJECT_FORM_ATTR = 'project/SET_PROJECT_FORM_ATTR'
export const RESET_PROJECT_FORM = 'project/RESET_PROJECT_FORM'

const initialState = {
  // request loading
  inFlight: false,

  // Request list of projects
  requestId: null,
  projectList: [],

  // Request single project
  selectedProject: {},
  singleRequestId: null,

  // fields
  fields: [],

  // Reuqest list of masks
  masksRequestId: null,
  masks: [],

  // which attribute is selected
  fieldSelectedId: null,
  maskSelectedId: null,

  // new mask to render
  newMaskShape: {},

  // form
  // content for project form (values and steps)
  form: {
    loading: false,
    name: null,
    methodology: null,
    unitSystem: null,
    step: null,
    projectType: '',
  },
}

// set a boundary to a specific field in the project state
const setNewFieldBoundary = (state, action) => {
  // changing from a state to a specific attribute
  // this allows to create a generic state for any use
  const fields = [...state.fields]
  const indexFound = fields?.findIndex(
    ({ ag_field_uuid }) => ag_field_uuid === state?.fieldSelectedId
  )

  if (indexFound === -1) return state

  fields[indexFound].geometry = action.geometry

  return {
    ...state,
    fields,
  }
}

const setNewMaskBoundary = (state, action) => {
  const indexFound = state?.masks?.findIndex(
    ({ id }) => id === state?.maskSelectedId
  )

  if (indexFound === -1) return state

  const masks = [...state.masks]
  masks[indexFound].geometry = action.geometry

  return {
    ...state,
    masks,
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT_ATTR:
      return {
        ...state,
        ...omit(action, 'type'),
      }
    case RESULTS:
      // the request id check is for slow debounces (taking the same reference from dashboard)
      return state.requestId === action.requestId
        ? {
            ...state,
            projectList: action.projectList,
            inFlight: false,
            requestId: initialState.requestId,
          }
        : state
    case PROJECT_RESULT:
      // works in the same way as for data collection, but keeping each request in a different state (loading and complete remain the same)
      return state.singleRequestId === action.singleRequestId
        ? {
            ...state,
            selectedProject: action.selectedProject,
            fields: action.selectedProject.ag_fields,
            inFlight: false,
            singleRequestId: initialState.singleRequestId,
          }
        : state
    case PROJECT_MASKS_RESULT:
      // works in the same way as for data collection, but keeping each request in a different state (loading and complete remain the same)
      return state.masksRequestId === action.masksRequestId
        ? {
            ...state,
            masks: action.masks,
            inFlight: false,
            masksRequestId: initialState.masksRequestId,
          }
        : state
    case PROJECT_RESET:
      return {
        ...state,
        fieldSelectedId: initialState.fieldSelectedId,
        maskSelectedId: initialState.maskSelectedId,

        masksRequestId: initialState.masksRequestId,
        masks: initialState.masks,
      }
    case PROJECT_SET_BOUNDARY:
      return setNewFieldBoundary(state, action)
    case PROJECT_SET_MASK_BOUNDARY:
      return setNewMaskBoundary(state, action)
    case PROJECT_PUSH_NEW_MASK:
      return { ...state, masks: [...state.masks, action.mask] }
    case PROJECT_SET_NEW_MASK_SHAPE:
      return { ...state, newMaskShape: action.newMaskShape }
    case PROJECT_SET_NEW_FIELDS:
      return { ...state, fields: action.fields }
    case SET_CURRENT_ORGANIZATION:
      return initialState
    case SET_PROJECT_FORM_ATTR:
      return {
        ...state,
        form: {
          ...state.form,
          ...omit(action, 'type'),
        },
      }
    case RESET_PROJECT_FORM:
      return {
        ...state,
        form: { ...initialState.form },
      }
    default:
      return state
  }
}

export default reducer
