import { DEFAULT_RATING_TIERS_IN_CO2E_TONS_PER_HECTARE_PER_YEAR } from '../constants'
import convertCO2ETonsPerHectareToPerAcre from '../utils/convertCO2ETonsPerHectareToPerAcre'

/**
 * Retrieves organization tier bands based on current organization data.
 *
 * @param {Object} currentOrganization - The current organization object.
 * @return {Object} An object containing rating tiers in CO2e tons per hectare per year and per acre per year.
 */

const getOrgTierBands = () => {
  const CURRENT_RATING_TIERS_IN_CO2E_TONS_PER_HECTARE_PER_YEAR =
    DEFAULT_RATING_TIERS_IN_CO2E_TONS_PER_HECTARE_PER_YEAR

  const roundTier = (tier) => {
    return tier.map((value) =>
      value != null ? Number(value.toFixed(2)) : null
    )
  }

  const convertTier = (tier) => {
    return tier.map(convertCO2ETonsPerHectareToPerAcre)
  }

  const RATING_TIERS_IN_CO2E_TONS_PER_HECTARE_PER_YEAR = {
    TIER1: roundTier(
      CURRENT_RATING_TIERS_IN_CO2E_TONS_PER_HECTARE_PER_YEAR.TIER1
    ),
    TIER2: roundTier(
      CURRENT_RATING_TIERS_IN_CO2E_TONS_PER_HECTARE_PER_YEAR.TIER2
    ),
    TIER3: roundTier(
      CURRENT_RATING_TIERS_IN_CO2E_TONS_PER_HECTARE_PER_YEAR.TIER3
    ),
    TIER4: [
      0,
      roundTier([
        CURRENT_RATING_TIERS_IN_CO2E_TONS_PER_HECTARE_PER_YEAR.TIER4[1],
      ])[0],
    ],
  }

  const RATING_TIERS_IN_CO2E_TONS_PER_ACRE_PER_YEAR = {
    TIER1: roundTier(
      convertTier(CURRENT_RATING_TIERS_IN_CO2E_TONS_PER_HECTARE_PER_YEAR.TIER1)
    ),
    TIER2: roundTier(
      convertTier(CURRENT_RATING_TIERS_IN_CO2E_TONS_PER_HECTARE_PER_YEAR.TIER2)
    ),
    TIER3: roundTier(
      convertTier(CURRENT_RATING_TIERS_IN_CO2E_TONS_PER_HECTARE_PER_YEAR.TIER3)
    ),
    TIER4: [
      0,
      roundTier([
        convertCO2ETonsPerHectareToPerAcre(
          CURRENT_RATING_TIERS_IN_CO2E_TONS_PER_HECTARE_PER_YEAR.TIER4[1]
        ),
      ])[0],
    ],
  }

  return {
    RATING_TIERS_IN_CO2E_TONS_PER_HECTARE_PER_YEAR,
    RATING_TIERS_IN_CO2E_TONS_PER_ACRE_PER_YEAR,
  }
}

export default getOrgTierBands
