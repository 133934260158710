/**
 * Normalizes a name by converting it to lowercase and replacing spaces with hyphens.
 * @param {string} name - The name to normalize.
 * @returns {string} The normalized name.
 */
const normalizeName = (name) => name?.toLowerCase().replace(/\s+/g, '-') || ''

/**
 * Validates that the given farm name does not already exist.
 * @param {object} _ - The form values.
 * @param {string} value - The name of the farm to check.
 * @param {Array} items - List of existing farm objects with 'name' property.
 * @returns {Promise} Resolves if the value is valid, rejects with an error if it is not.
 */
const validateFarmName = (_, value, items) => {
  if (!value) {
    return Promise.resolve()
  }

  const normalizedValue = normalizeName(value)
  const existingNames = (items || []).map((item) => normalizeName(item.name))

  return existingNames.includes(normalizedValue)
    ? Promise.reject(new Error('Farm name already exists!'))
    : Promise.resolve()
}

export default validateFarmName
