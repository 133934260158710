import './style.scss'
import React from 'react'
import { RadioGroup } from '@mui/material'

import { SimpleRadio } from './SimpleRadio'

export const SimpleRadioGroup = ({ radios, ...props }) => {
  return (
    <RadioGroup {...props} className="simple-radio-group-container">
      {radios.map(({ label, value, disabled }) => {
        return (
          <SimpleRadio
            label={label}
            value={value}
            key={label}
            disabled={disabled}
          />
        )
      })}
    </RadioGroup>
  )
}
