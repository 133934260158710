/**
 * Animates the width of the container over time.
 * @param {number} startWidth - The initial width of the container.
 * @param {number} targetWidth - The target width of the container.
 * @param {number} duration - The duration of the animation in milliseconds.
 * @param {function} trigger - A callback function that is called with the new
 * width of the container at each animation frame. The function is responsible for
 * updating the state of the container width and the button left position.
 * @returns {void}
 * Animates the width of the container by requesting animation frames and updating
 * the state of the container width and the button left position accordingly.
 * The animation uses an ease-in-out-quadratic timing function, which is slow at the start and end,
 * and fast in the middle. The function is defined as follows:
 * t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
 */
const animateWidth = (startWidth, targetWidth, duration, trigger) => {
  const deltaWidth = targetWidth - startWidth
  const startTime = performance.now()

  /**
   * The ease-in-out-quadratic timing function.
   * @param {number} t - The time value to be eased.
   * @returns {number} The eased time value.
   * This function maps the input time value from 0 to 1 to an output time value from 0 to 1
   * with an ease-in-out-quadratic timing function. The curve is slow at the start and end, and
   * fast in the middle. The function is defined as follows:
   * t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
   */

  const easeInOutQuad = (t) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
  }

  /**
   * The animation function that requests animation frames and updates the state
   * of the container width and the button left position accordingly.
   * @param {number} currentTime - The current time.
   * @returns {void}
   * Calculates the elapsed time from the start of the animation, maps it to an
   * ease-in-out-quadratic timing function, and updates the state of the container
   * width and the button left position accordingly. If the animation is not
   * finished, requests the next animation frame.
   */
  const animate = (currentTime) => {
    const elapsedTime = currentTime - startTime
    const progress = Math.min(elapsedTime / duration, 1)
    const easedProgress = easeInOutQuad(progress)
    const newWidth = startWidth + deltaWidth * easedProgress

    trigger(newWidth) // Delegate the state update to external trigger

    if (progress < 1) {
      requestAnimationFrame(animate)
    }
  }

  requestAnimationFrame(animate)
}

export default animateWidth
