import './App.scss'
import React from 'react'
import { Provider } from 'react-redux'
import Layout from './Layout'
import { store, persistor } from './redux/store'
import { Spin } from 'antd'
import { PersistGate } from 'redux-persist/integration/react'
import ErrorBoundary from './components/ErrorBoundary'
import { useRemoveResizeObserverWarning } from './hooks/useErrorUtitlities'

const App = () => {
  useRemoveResizeObserverWarning()
  return (
    <Provider store={store}>
      <useFieldTransctionProgress />
      <PersistGate loading={<Spin />} persistor={persistor}>
        <ErrorBoundary>
          <Layout />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  )
}

export default App
