import { API, Auth } from 'aws-amplify'
import { store } from '../redux/store'

const AMPLIFY_API_NAME = 'cloudag'

const prepareQueryString = (params) => new URLSearchParams(params).toString()

const headers = (token) => ({
  'X-AWS-Identity': store.getState().user.identity,
  Authorization: `Bearer ${token}`,
})

const getToken = () =>
  Auth.currentSession().then((res) => res.getIdToken().getJwtToken())

const get = (uri) =>
  getToken()
    .then(headers)
    .then((head) => API.get(AMPLIFY_API_NAME, uri, { headers: head }))

const post = (uri, params) =>
  getToken()
    .then(headers)
    .then((head) =>
      API.post(AMPLIFY_API_NAME, uri, { body: params, headers: head })
    )

const put = (uri, params) =>
  getToken()
    .then(headers)
    .then((head) =>
      API.put(AMPLIFY_API_NAME, uri, { body: params, headers: head })
    )

const del = (uri, params) =>
  getToken()
    .then(headers)
    .then((head) =>
      API.del(AMPLIFY_API_NAME, uri, { body: params, headers: head })
    )

export const APIS = {
  // Users
  getUsers: (params) => get(`/org/users?${prepareQueryString(params)}`),
  inviteUser: (params) => post('/org/invite-users', params),
  resendInviteUser: (params) => post('/org/invite-users/resend', params),
  updateUser: (id, params) => put(`/org/users/${id}`, params),
  deleteInviteUser: (params) => del('/org/invite-users', params),
  initUser: () => get(`/users/v2/me`),
  getPermissions: (params) =>
    get(`/org/users/permissions?${prepareQueryString(params)}`),
  cognitoCallback: () => post(`/users/cognito/callback`),

  // Roles
  getRoles: (params) => get(`/org/roles?${prepareQueryString(params)}`),
  getRole: (id) => get(`/org/roles/${id}`),

  // Organizations
  getOrganizations: (params) =>
    get(`/admin/organizations?${prepareQueryString(params)}`),
  createOrganization: (params) => post('/admin/organizations', params),
  updateOrganization: (id, params) => put(`/admin/organizations/${id}`, params),
  deleteOrganization: (id) => del(`/admin/organizations/${id}`),
  getOrganization: (id) => get(`/admin/organizations/${id}`),

  // Roles Features
  getRoleFeatures: (params) =>
    get(`/admin/role-features?${prepareQueryString(params)}`),

  // Invite List
  getInvitations: (params) =>
    get(`/org/invite-users?${prepareQueryString(params)}`),
  respondInvitation: (params) => post(`/org/respond-invite`, params),
  declineInvitation: (params) => del(`/org/invite-users`, params),
  deleteInvitation: (params) => del(`/org/invite-users`, params), // TODO: Why are there two names for the same

  processShapeFile: (params) =>
    post('/org/ag_fields_transaction/process_files', params),
  getUploadProgress: (id, params) =>
    get(
      `/org/ag_fields_transaction/upload_progress/${id}?${prepareQueryString(
        params
      )}`
    ),
  getPresignedUrl: (params) =>
    get(`/org/presigned_url?${prepareQueryString(params)}`),

  // Ag Field
  listAgFields: (params) => post(`/org/ag_fields/search`, params),
  bulkDeleteAgFields: (params) => del(`/org/ag_fields`, params),
  bulkUpdateAgFields: (params) => put(`/org/ag_fields`, params),
  updateAgField: (id, params) => put(`/org/ag_fields/${id}`, params),
  getAgField: (id, params) =>
    get(`/org/ag_fields/${id}?${prepareQueryString(params)}`),
  deleteAgField: (id) => del(`/org/ag_fields/${id}`),
  getAgFieldAssets: (id, params) =>
    get(`/org/ag_fields/${id}/assets?${prepareQueryString(params)}`),
  getRanches: (params) => get(`/org/farms?${prepareQueryString(params)}`),
  getTags: (params) => get(`/org/ag_fields/tags?${prepareQueryString(params)}`),
  createRanch: (params) => post(`/org/farms`, params),
  addFieldsToRanch: (id, params) => post(`/org/farms/${id}/fields`, params),
  removeFieldsFromRanch: (id, params) => del(`/org/farms/${id}/fields`, params),
  getFieldsSummary: (params) =>
    get(`/org/ag_fields/summary?${prepareQueryString(params)}`),
  filterFieldsSummary: (params) => post(`/org/ag_fields/summary`, params),
  getStrataInformation: (id, params) =>
    get(`/org/strata/${id}?${prepareQueryString(params)}`),

  // Unassigned Field
  listUnassignedFields: (params) =>
    get(`/org/unassigned_fields?${prepareQueryString(params)}`),

  // Dashboard
  getStats: (params) => get(`/org/stats?${prepareQueryString(params)}`),

  // Work Orders
  getWorkOrders: (params) =>
    get(`/org/work_orders?${prepareQueryString(params)}`),
  getWorkOrder: (id, params) =>
    get(`/org/work_orders/${id}?${prepareQueryString(params)}`),
  createWorkOrder: (params) => post(`/org/work_orders`, params),
  updateWorkOrder: (id, params) => put(`/org/work_orders/${id}`, params),
  addToWorkOrder: (id, params) => post(`/org/work_orders/${id}/fields`, params),
  getWorkOrderPhotos: (id, params) =>
    get(`/org/assigned_work_orders/${id}/photos?${prepareQueryString(params)}`),
  // Work Order Field
  getWorkOrderField: (work_order_id, ag_field_id, params) =>
    get(
      `/org/work_orders/${work_order_id}/${ag_field_id}?${prepareQueryString(
        params
      )}`
    ),
  deleteWorkOrderField: (id, params) =>
    del(`/org/work_orders/${id}/fields`, params),
  //Integrations
  initiateOauth: (params) =>
    get(`/org/oauth/initiate?${prepareQueryString(params)}`),
  redirectOauth: (params) =>
    get(`/org/oauth/redirect?${prepareQueryString(params)}`),

  // Projects
  postProject: (params) => post(`/org/projects`, params),
  postFieldToProject: (id, params) =>
    post(`/org/projects/${id}/fields`, params),
  getProjects: (params) => get(`/org/projects?${prepareQueryString(params)}`),
  getProjectData: (id, params) =>
    get(`/org/projects/${id}?${prepareQueryString(params)}`),
  deleteFieldFromProject: (id, params) =>
    del(`/org/projects/${id}/fields`, params),

  // Masks
  getFieldMasks: (id, params) =>
    get(`/org/ag_fields/${id}/masks?${prepareQueryString(params)}`),
  updateMask: (id, params) => put(`/org/masks/${id}`, params),
  postMask: (id, params) => post(`/org/ag_fields/${id}/masks`, params),
  deleteMask: (id, params) => del(`/org/masks/${id}`, params),

  // Stratification
  postStratificationParameters: (params) =>
    post(`/org/sampling_plan/initialize`, params),
  getStratificationStatus: (id, params) =>
    get(`/org/sampling_plan/status/${id}?${prepareQueryString(params)}`),

  //lookup
  getAdministrativeAreas: (params) =>
    get(`/lookup/administrative_areas?${prepareQueryString(params)}`),
  getCountryCodes: (params) =>
    get(`/lookup/countries?${prepareQueryString(params)}`),
}
